import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { buildQuery } from '../../../utils/appHelpers'

const initialState = {
    terms: { data: [], totalCount: 0 },
    termLookups: [],
    termConfig: { termId: null, termYear: null, termNumber: null },
    loading: false,
    error: null,
    refreshing: false,
}

const termSlice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTermConfig: (state, action) => {
            state.termConfig = action.payload
        },
        updateTermConfig: (state, action) => {
            state.termConfig = action.payload
        },
        getTerms: (state, action) => {
            state.terms = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTermLookup: (state, action) => {
            state.termLookups = action.payload
            state.loading = false
        },
        getExistingTermLookupById: (state, action) => {
            const index = state.termLookups.findIndex((term) => term.id === action.payload.id)
            if (index >= 0) state.termLookups[index] = action.payload
            else {
                state.termLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingTermById: (state, action) => {
            const index = state.terms.data.findIndex((term) => term.id === action.payload.id)
            if (index >= 0) state.terms[index] = action.payload
            else {
                state.terms.data.push(action.payload)
                state.terms.totalCount++
            }
            state.loading = false
        },
        addNewTerm: (state, action) => {
            state.terms.data.push(action.payload)
            state.terms.totalCount++
            state.loading = false
        },
        updateExistingTerm: (state, action) => {
            const index = state.terms.data.findIndex((term) => term.id === action.payload.id)
            if (index >= 0) state.terms[index] = action.payload
            state.loading = false
        },
        removeTerm: (state, action) => {
            const index = state.terms.data.findIndex((term) => term.id === action.payload.id)
            if (index >= 0) {
                state.terms.data.splice(index, 1)
                state.terms.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getTermConfig,
    updateTermConfig,
    getTerms,
    getTermLookup,
    getExistingTermLookupById,
    getExistingTermById,
    addNewTerm,
    updateExistingTerm,
    removeTerm,
} = termSlice.actions
export default termSlice.reducer

export const getTermList = (filter, refresh = false) => {
    let url = `/api/course/Term/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getTerms.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTermLookupList = (queryParams) => {
    let url = buildQuery(`/api/course/Term/lookup/list`, queryParams)
    // let url = filter?.termYear
    //     ? `/api/course/Term/lookup/list?search=${filter.termYear}`
    //     : `/api/course/Term/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getTermLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTermLookupById = (filter) => {
    let url = `/api/course/Term/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTermLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTermById = (filter) => {
    let url = `/api/course/Term/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTermById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addTerm = (term) => {
    let url = `/api/course/Term/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: term,
        onStart: apiRequested.type,
        onSuccess: addNewTerm.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTerm = (term) => {
    let url = `/api/course/Term/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: term,
        onStart: apiRequested.type,
        onSuccess: updateExistingTerm.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteTerm = (term) => {
    let url = `/api/course/Term/delete`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: term,
        onStart: apiRequested.type,
        onSuccess: removeTerm.type,
        onError: apiRequestedFailed.type,
    })
}
