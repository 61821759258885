import React, { useEffect, useState } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { Paper } from '@mui/material'
import { getTxnPaymentList } from 'app/redux/store/features/transactions'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const TxnPaymentGrid = ({ id }) => {
    const pageSize = 10
    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState({
        txnId: id,
    })
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [sorting, setSorting] = useState([{ columnName: 'termYear', direction: 'desc' }])
    const dateColumns = ['datePayment']
    const { transactionPayment, loading, error } = useSelector((state) => state.transactions)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getTxnPaymentList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getTxnPaymentList(currentFilter))
        setFilter(currentFilter)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const columns = [
        { name: 'datePayment', title: 'Payment Date' },
        { name: 'paymentAmount', title: 'Payment Amount', getCellValue: (row) => formatCurrency(row.paymentAmount) },
        { name: 'txnNumber', title: 'Paid Txn No' },
        { name: 'comments', title: 'Comments' },
    ]

    const tableColumnExtensions = [
        { columnName: 'paymentAmount', align: 'right', width: '14%' },
        { columnName: 'datePayment', width: '14%' },
        { columnName: 'txnNumber', width: '14%', align: 'center' },
        { columnName: 'comments' },
    ]

    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pl: 1,
            }}>
            <Paper style={{ position: 'relative' }}>
                <Grid rows={transactionPayment.data} columns={columns}>
                    <DateTimeTypeProvider for={dateColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                </Grid>
            </Paper>
        </JumboCardQuick>
    )
}

export default TxnPaymentGrid
