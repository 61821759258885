import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { getGlobalConfigList, updateGlobalConfig } from 'app/redux/store/features/configs'
import { useSnackbar } from 'notistack'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const ConfigForm = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit } = useForm()
    const { enqueueSnackbar } = useSnackbar()
    const { global } = useSelector((state) => state.configs)

    useEffect(() => {
        dispatch(getGlobalConfigList())
    }, [])

    const [globalConfigState, setGlobalConfigState] = useState({ ...global })

    useEffect(() => {
        setGlobalConfigState({ ...global })
    }, [global])

    const saveButtonOptions = {
        text: 'Save Changes',
        type: 'danger',
        width: 180,
        icon: 'check',
        useSubmitBehavior: true,
    }

    const onUpdateConfig = useCallback(async () => {
        props.setApiCallInProgress(true)
        const responseData = dispatch(updateGlobalConfig(globalConfigState))
        if (responseData) {
            // On Global Config data updated successfull
            enqueueSnackbar('Global Config data is updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }, [saveButtonOptions])

    const amountEditorOptions = {
        format: (data) => formatCurrency(data), // Pass the formatCurrency function directly
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Global Config
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(onUpdateConfig)}>
                    <Form
                        mode='form'
                        formData={globalConfigState}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Edit Config' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <GroupItem>
                                    <SimpleItem
                                        dataField='txnDueDays'
                                        editorType='dxNumberBox'
                                        editorOptions={amountEditorOptions}
                                    />
                                    <SimpleItem
                                        dataField='maxOutstandingAmount'
                                        editorType='dxNumberBox'
                                        editorOptions={amountEditorOptions}
                                    />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={1} md={1} sm={1} xs={1} />
                            <ButtonItem horizontalAlignment='center' buttonOptions={saveButtonOptions} />
                        </GroupItem>
                        <EmptyItem />
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}
export default ConfigForm
