import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { getTermById, deleteTerm } from 'app/redux/store/features/terms'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'

const TermGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const dateTimeColumns = ['dateStart', 'dateEnd', 'createTime', 'updateTime']
    const booleanColumns = ['isCurrent']
    const [sorting, setSorting] = useState([{ columnName: 'termYear', direction: 'asc' }])
    const pageSize = 25
    const { terms, loading, error, refreshing } = useSelector((state) => state.terms)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getTermById({ id: id }))
        navigate(`/courses/setup/term/edit/${id}`, { state: { termId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.id)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton>
                    <EditIcon
                        sx={{ color: 'primary.main', fontSize: '18px' }}
                        onClick={() => {
                            onNavigatingToDetail(row.id)
                        }}
                    />
                </IconButton>
                <IconButton>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} onClick={() => onRemoveUser(row.id)} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.termYear}
            </Link>
        )
    }

    const BooleanFormatter = ({ value }) => (value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)

    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Term removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (userId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteTerm({ id: userId }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const [columns] = useState([
        { name: 'termYear', title: 'Year', getCellValue: renderLinkCell },
        { name: 'termNumber', title: 'Number' },
        { name: 'termName', title: 'Term Name', width: 400 },
        { name: 'dateStart', title: 'Start Date' },
        { name: 'dateEnd', title: 'End Date' },
        { name: 'createTime', title: 'Created Date' },
        { name: 'updateTime', title: 'Updated Date' },
        { name: 'createdBy', title: 'Created By' },
        { name: 'isCurrent', title: 'Current Term' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ])

    const columnWidth = useMemo(
        () => [
            { columnName: 'termYear', width: min ? '100px' : '9%', minWidth: '100px' },
            { columnName: 'termNumber', width: min ? '100px' : '10%', minWidth: '100px' },
            { columnName: 'termName', width: min ? '120px' : '10%', wordWrapEnabled: true, minWidth: '120px' },
            { columnName: 'dateStart', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'dateEnd', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'createTime', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'updateTime', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'createdBy', width: min ? '100px' : '10%', wordWrapEnabled: true, minWidth: '100px' },
            {
                columnName: 'isCurrent',
                width: min ? '100px' : '10%',
                minWidth: '100px',
                align: 'center',
                wordWrapEnabled: true,
            },
            {
                columnName: 'Action',
                width: min ? '90px' : '9%',
                minWidth: '90px',
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={terms.data} columns={columns}>
                {terms.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <DateTimeTypeProvider for={dateTimeColumns} />
                <BooleanTypeProvider for={booleanColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={terms.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default TermGridData
