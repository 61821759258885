import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { addSearchImage, deleteSearchImage, getSearchImage } from 'app/redux/store/features/classes'
import ChangeSearchImageUpload from './ChangeSearchImageUpload'
import { updateIndicator } from 'app/redux/store/features/configs'

const UpdateSearchImage = ({ filePathSearchData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { classId } = state
    const { classSearchImage, loading, error } = useSelector((state) => state.classes)
    const [searchImage, setSearchImage] = useState({ ...classSearchImage })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Operation completed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            dispatch(updateIndicator({ inProgress: false }))
            navigate('/courses/setup/classes', { state: { navigateFrom: 'update-class' } })
        }
    }, [loading])

    useEffect(() => {
        if (!utilServices.isNullOrUndefined(classId)) {
            dispatch(getSearchImage({ id: classId }))
        }
    }, [])

    useEffect(() => {
        setSearchImage({ ...classSearchImage })
    }, [classSearchImage])

    const onAddSearchImage = useCallback(
        (e) => {
            if (searchImage.file.length <= 0) {
                enqueueSnackbar('Please select an image and try again', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
                e.preventDefault()
                return
            }
            e.preventDefault()
            setApiCallInProgress(true)

            const form = new FormData()
            form.append('id', classId)
            form.append('image', searchImage.file[0])

            dispatch(
                addSearchImage(form, {
                    'Content-Type': 'multipart/form-data',
                })
            )
                .then(() => {
                    setApiCallInProgress(false)
                    dispatch(getSearchImage({ id: classId }))
                })
                .catch(handleError)
        },
        [searchImage]
    )

    const onRemoveSearchImage = useCallback((e) => {
        e.event.preventDefault()
        setApiCallInProgress(true)
        dispatch(deleteSearchImage({ id: classId }))
    }, [])

    return (
        <React.Fragment>
            <ChangeSearchImageUpload
                searchImage={searchImage}
                filePathSearchData={filePathSearchData}
                onAddSearchImage={onAddSearchImage}
                onRemoveSearchImage={onRemoveSearchImage}
            />
        </React.Fragment>
    )
}

export default UpdateSearchImage
