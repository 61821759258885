import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AddClassForm from './AddClassForm'
import { defaultClass } from './defaultClass'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import locationServices from 'app/services/pages/settings/definitions/locationServices/locationServices'
import teacherServices from 'app/services/pages/security/teacherServices/teacherServices'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import classTypeServices from 'app/services/pages/settings/definitions/classTypeServices/classTypeServices'
import classesService from '../../../../../../../../services/pages/courses/setup/classesServices/classesService'
import GenderTypeService from '../../../../../../../registrations/customers/components/GenderTypeService'
import ageGroupService from 'app/services/pages/settings/definitions/ageGroupServices/ageGroupServices'

const AddClassGrid = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { id } = state
    const [classData] = useState({ ...defaultClass, termId: id })
    const { termConfig } = useSelector((state) => state.terms)

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const genderEditorOptions = {
        dataSource: GenderTypeService.getGenderType(),
        displayExpr: 'genderType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const termLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await termServices.getTermLookupList({ termYear: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [termServices.getTermLookupList]
    )

    const termLookupById = useCallback(
        async (key) => {
            try {
                return await termServices.getTermLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [termServices.getTermLookupById]
    )

    const termLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await termLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await termLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const termEditorOptions = {
        dataSource: termLookupsStore,
        displayExpr: function (data) {
            return data ? data.termYear + ' / ' + data.termNumber : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data.termYear + ' / ' + data.termNumber
        },
    }

    const classTypeLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await classTypeServices.getClassTypeLookupList({ classTypeName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [classTypeServices.getClassTypeLookupList]
    )

    const classTypeLookupById = useCallback(
        async (key) => {
            try {
                return await classTypeServices.getClassTypeLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [classTypeServices.getClassTypeLookupById]
    )

    const classTypeLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await classTypeLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await classTypeLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const classTypeEditorOptions = {
        dataSource: classTypeLookupsStore,
        displayExpr: 'classTypeName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const locationLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await locationServices.getLocationLookupList({ locationName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [locationServices.getLocationLookupList]
    )

    const locationLookupById = useCallback(
        async (key) => {
            try {
                return await locationServices.getLocationById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [locationServices.getLocationById]
    )

    const locationLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await locationLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await locationLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const locationEditorOptions = {
        dataSource: locationLookupsStore,
        displayExpr: 'locationName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const classLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (utilServices.isNullOrUndefined(termConfig?.termId)) return utilServices.emptyList()
                return await classesService.getPrvTermClassLookupList({
                    termId: termConfig.termId,
                    className: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [classesService, termConfig.termId]
    )

    const classLookupById = useCallback(
        async (key) => {
            try {
                return await classesService.getClassLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [classesService]
    )

    const classLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await classLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await classLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const prvClassEditorOptions = {
        dataSource: classLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data ? data.termYear + '/' + data.termNumber + ', ' + data.className : ''
        },
        displayExpr: function (data) {
            return data ? data.termYear + '/' + data.termNumber + ', ' + data.className : ''
        },
    }

    const teacherLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await teacherServices.getTeacherLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [teacherServices.getTeacherLookupList]
    )

    const teacherLookupById = useCallback(
        async (key) => {
            try {
                return await teacherServices.getTeacherById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [teacherServices.getTeacherById]
    )

    const teacherLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await teacherLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await teacherLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const teacherEditorOptions = {
        dataSource: teacherLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
    }

    const ageGroupLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await ageGroupService.getAgeGroupLookupList({ ageGroupName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [ageGroupService.getAgeGroupLookupList]
    )

    const ageGroupLookupById = useCallback(
        async (key) => {
            try {
                return await ageGroupService.getAgeGroupLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [ageGroupService.getAgeGroupLookupById]
    )

    const ageGroupLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ageGroupLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await ageGroupLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const ageGroupEditorOptions = {
        dataSource: ageGroupLookupsStore,
        displayExpr: 'ageGroupName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <AddClassForm
                classData={classData}
                teacherEditorOptions={teacherEditorOptions}
                classTypeEditorOptions={classTypeEditorOptions}
                prvClassEditorOptions={prvClassEditorOptions}
                locationEditorOptions={locationEditorOptions}
                termEditorOptions={termEditorOptions}
                genderEditorOptions={genderEditorOptions}
                ageGroupEditorOptions={ageGroupEditorOptions}
                handleError={handleError}
            />
        </React.Fragment>
    )
}

export default AddClassGrid
