import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { getLocationById, selectItemById, updateLocation } from 'app/redux/store/features/locations'
import UpdateLocationForm from './UpdateLocationForm'
import utilServices from 'app/services/util-services'

const UpdateLocation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const { state } = useLocation()
    const { locationId } = state
    const { locations, loading, error } = useSelector((state) => state.locations)
    const location = selectItemById(locations, locationId)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(location.id)) dispatch(getLocationById({ id: locationId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Location updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/definitions')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateLocation = async () => {
        setApiCallInProgress(true)
        dispatch(updateLocation({ ...location }))
    }

    return (
        <React.Fragment>
            <UpdateLocationForm locationData={location} updateLocation={onUpdateLocation} />
        </React.Fragment>
    )
}

export default UpdateLocation
