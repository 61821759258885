import React from 'react'

const Chat = () => {
    return (
        <div>
            <h2>Islamic Centre of WA - Chat</h2>
            <p>This page is just to showcase the way you can add your own pages.</p>
        </div>
    )
}

export default Chat
