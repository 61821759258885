import React from 'react'
import { List, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import RightInfoPanelItem from './RightInfoPanelItem'
import Div from '@jumbo/shared/Div'
import { useSelector } from 'react-redux'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'

const RightInfoPanelList = ({ scrollHeight }) => {
    const { overduePayments } = useSelector((state) => state.dashboard)
    const { theme } = useJumboTheme()
    const noDataImgUrl = '/images/no_data.png'

    return (
        <JumboCardQuick
            title={
                <Div
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        },
                    }}>
                    <Typography
                        variant='h3'
                        mb={0}
                        fontFamily='Lobster, cursive'
                        color='primary.dark'
                        sx={{
                            minWidth: 245,
                            [theme.breakpoints.down('md')]: {
                                minWidth: '100%',
                                marginBottom: 2,
                            },
                        }}>
                        Overdue Payments
                    </Typography>
                </Div>
            }
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -0.75,
                },
            }}
            wrapperSx={{
                p: 0,
                '&:last-child': {
                    pb: 2,
                },
                '& .MuiCollapse-entered:last-child': {
                    '& .MuiListItemButton-root': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                },
            }}>
            {overduePayments.data.length > 0 ? (
                <JumboScrollbar
                    autoHeight
                    autoHeightMin={scrollHeight || 585}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}>
                    <List disablePadding>
                        {overduePayments.data.map((item, index) => {
                            return <RightInfoPanelItem item={item} key={index} />
                        })}
                    </List>
                </JumboScrollbar>
            ) : (
                <Div sx={{ textAlign: 'center', p: 3, m: 'auto' }}>
                    <img alt={'No Data'} src={noDataImgUrl} width='150' style={{ verticalAlign: 'middle' }} />
                    <Typography variant={'h3'} color={'text.secondary'} mt={2}>
                        No overdue Payments
                    </Typography>
                </Div>
            )}
        </JumboCardQuick>
    )
}

export default RightInfoPanelList
