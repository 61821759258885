import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Div from '@jumbo/shared/Div'
import { useDispatch } from 'react-redux'
import { updateUserTypeConfig } from 'app/redux/store/features/users'

const UserTypeItem = ({ icon, label, path, count, selected, userTypeId, permissionsReadOnly }) => {
    const dispatch = useDispatch()

    const onUpdateUserType = async () => {
        await dispatch(updateUserTypeConfig({ id: userTypeId, readOnly: permissionsReadOnly }))
    }

    return (
        <Div>
            <MenuItem disableRipple sx={{ p: 0, mb: 2 }} selected={selected} onClick={() => onUpdateUserType()}>
                <Link underline={'none'} component={RouterLink} to={path}>
                    {icon && <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>}
                    <ListItemText>{label}</ListItemText>
                    {count > 0 && <Chip size={'small'} label={count} />}
                </Link>
            </MenuItem>
            <Divider component='li' />
        </Div>
    )
}

export default UserTypeItem
