import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    transactions: { data: [], totalCount: 0 },
    transactionLookups: [],
    transactionItems: { data: [], totalCount: 0 },
    transactionPayment: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTransactions: (state, action) => {
            state.transactions = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTransactionLookup: (state, action) => {
            state.transactionLookups = action.payload
            state.loading = false
        },
        getExistingTransactionLookupById: (state, action) => {
            const index = state.transactionLookups.findIndex((transaction) => transaction.id === action.payload.id)
            if (index >= 0) state.transactionLookups[index] = action.payload
            else {
                state.transactionLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingTransactionById: (state, action) => {
            const index = state.transactions.data.findIndex((transaction) => transaction.id === action.payload.id)
            if (index >= 0) state.transactions[index] = action.payload
            else {
                state.transactions.data.push(action.payload)
                state.transactions.totalCount++
            }
            state.loading = false
        },
        getTransactionItems: (state, action) => {
            state.transactionItems = action.payload
            state.loading = false
        },
        getTransactionPayment: (state, action) => {
            state.transactionPayment = action.payload
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getTransactions,
    getTransactionLookup,
    getExistingTransactionLookupById,
    getExistingTransactionById,
    getTransactionItems,
    getTransactionPayment,
} = transactionSlice.actions
export default transactionSlice.reducer

export const getTransactionList = (filter, refresh = false) => {
    let url = `/api/Txn/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getTransactions.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionLookupList = (filter) => {
    let url = filter?.firstName ? `/api/Txn/lookup/list?search=${filter.firstName}` : `/api/Txn/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransactionLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionLookupById = (filter) => {
    let url = `/api/Txn/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTransactionLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransactionById = (filter) => {
    let url = `/api/Txn/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTransactionById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTxnItemList = (filter) => {
    let url = `/api/Txn/txnItem/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransactionItems.type,
        onError: apiRequestedFailed.type,
    })
}
export const getTxnPaymentList = (filter) => {
    let url = `/api/Txn/txnPayment/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransactionPayment.type,
        onError: apiRequestedFailed.type,
    })
}
