import React from 'react'
import Page from '@jumbo/shared/Page'
import Configuration from '../pages/settings/configuration'
import Definitions from '../pages/settings/definitions'
import AddLocation from '../pages/settings/definitions/components/locations/components/AddLocation'
import UpdateLocation from '../pages/settings/definitions/components/locations/components/UpdateLocation'
import AddBannerBar from 'app/pages/settings/configuration/bannerbar/components/AddBannerBar'
import UpdateBannerBar from '../pages/settings/configuration/bannerbar/components/UpdateBannerBar'
import AddInfoBar from 'app/pages/settings/configuration/infobar/components/AddInfoBar'
import UpdateInfoBar from 'app/pages/settings/configuration/infobar/components/UpdateInfoBar'
import AddNotifyBar from 'app/pages/settings/configuration/notifybar/components/AddNotifyBar' 
import UpdateNotifyBar from 'app/pages/settings/configuration/notifybar/components/UpdateNotifyBar'
import AddClassType from 'app/pages/settings/definitions/components/classTypes/components/AddClassType'
import UpdateClassType from 'app/pages/settings/definitions/components/classTypes/components/UpdateClassType'
import AddAgeGroup from 'app/pages/settings/definitions/components/ageGroup/components/AddAgeGroup'
import UpdateAgeGroup from 'app/pages/settings/definitions/components/ageGroup/components/UpdateAgeGroup'

const settingRoutes = [
    {
        path: ['/settings/configuration', '/settings/configuration/:selectedTab'],
        element: <Page component={Configuration} />,
    },
    {
        path: ['/settings/configuration/banner-bar/add'],
        element: <Page component={AddBannerBar} />,
    },
    {
        path: ['/settings/configuration/banner-bar/edit/:id'],
        element: <Page component={UpdateBannerBar} />,
    },
    {
        path: ['/settings/configuration/info-bar/add'],
        element: <Page component={AddInfoBar} />,
    },
    {
        path: ['/settings/configuration/info-bar/edit/:id'],
        element: <Page component={UpdateInfoBar} />,
    },
    {
        path: ['/settings/configuration/notify-bar/add'],
        element: <Page component={AddNotifyBar} />,
    },
    {
        path: ['/settings/configuration/notify-bar/edit/:id'],
        element: <Page component={UpdateNotifyBar} />,
    },
    {
        path: ['/settings/definitions', '/settings/definitions/:selectedTab'],
        element: <Page component={Definitions} />,
    },
    {
        path: ['/settings/definitions/class-types/add'],
        element: <Page component={AddClassType} />,
    },
    {
        path: ['/settings/definitions/class-types/edit/:id'],
        element: <Page component={UpdateClassType} />,
    },
    {
        path: ['/settings/definitions/age-group/add'],
        element: <Page component={AddAgeGroup} />,
    },
    {
        path: ['/settings/definitions/age-group/edit/:id'],
        element: <Page component={UpdateAgeGroup} />,
    },    
    {
        path: ['/settings/definitions/locations/add'],
        element: <Page component={AddLocation} />,
    },
    {
        path: ['/settings/definitions/:tabTitle/edit/:locationId'],
        element: <Page component={UpdateLocation} />,
    },
]

export default settingRoutes
