import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import Div from '@jumbo/shared/Div'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { deleteClass, getClassById, updateClassConfig } from 'app/redux/store/features/classes'
import ClassDuplicateForm from './ClassDuplicateForm'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import { makeStyles } from '@mui/styles'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'

const ClassGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
    handleError,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const [sorting, setSorting] = useState([{ columnName: 'className', direction: 'asc' }])
    const [selectedRowIds, setSelectedRowIds] = useState([])
    const pageSize = 12
    const { classes, classConfig, loading, error, refreshing } = useSelector((state) => state.classes)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading, classConfig])

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getClassById({ id: id }))
        navigate(`/courses/setup/classes/edit/${id}`, { state: { classId: id } })
    }

    const useStyles = makeStyles((theme) => ({
        selectedRow: {
            backgroundColor: theme.palette.action.selected + ' !important',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))
    const TableRow = ({ tableRow, ...restProps }) => {
        const classes = useStyles()
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.id)
                }}
                onClick={() => {
                    dispatch(
                        updateClassConfig({
                            classId: tableRow.row.id,
                        })
                    )
                    setSelectedRowIds([tableRow.row.id])
                }}
                selected={selectedRowIds.includes(tableRow.row.id)}
                className={selectedRowIds.includes(tableRow.row.id) ? classes.selectedRow : null}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const onSelectionChange = (selectedRowIds) => {
        setSelectedRowIds(selectedRowIds)
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.id)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onDuplicateClass(row)}>
                    <ContentCopyIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveUser(row.id)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.className}
            </Link>
        )
    }

    const renderTeacherCell = (row) => {
        return row.teacherFirstName ? row.teacherFirstName + ' ' + row.teacherLastName : ''
    }

    const onDuplicateClass = (row) => {
        showDialog({
            title: (
                <Div
                    sx={{
                        textAlign: 'center',
                        height: 48,
                        pt: 1.5,
                        bgcolor: 'success.main',
                        borderRadius: 1,
                        color: 'common.white',
                    }}>
                    Duplicate Class and Sections
                </Div>
            ),
            content: (
                <ClassDuplicateForm
                    classId={row.id}
                    termId={row.termId}
                    termYear={row.termYear}
                    termNumber={row.termNumber}
                    onRefresh={onRefresh}
                    handleError={handleError}
                />
            ),
        })
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Class data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (id) => {
        await showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteClass({ id: id }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'className', title: 'Class Name', getCellValue: renderLinkCell },
        { name: 'subTitle', title: 'Sub Title' },
        { name: 'classTypeName', title: 'Class Type' },
        { name: 'teacherFirstName', title: 'Teacher Name', getCellValue: renderTeacherCell },
        { name: 'capacity', title: 'Capacity' },
        { name: 'termInfo', title: 'Term Info' },
        { name: 'shortDesc', title: 'Age Info' },
        { name: 'classPrice', title: 'Class Price', getCellValue: (row) => formatCurrency(row.classPrice) },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'className', width: min ? '140px' : '17%', wordWrapEnabled: true, minWidth: '140px' },
            { columnName: 'subTitle', width: min ? '160px' : '13%', wordWrapEnabled: true, minWidth: '160px' },
            { columnName: 'classTypeName', width: min ? '120px' : '14%', wordWrapEnabled: true, minWidth: '120px' },
            { columnName: 'teacherFirstName', width: min ? '140px' : '17%', wordWrapEnabled: true, minWidth: '140px' },
            {
                columnName: 'capacity',
                width: min ? '110px' : '18%',
                minWidth: '110px',
                align: 'center',
            },
            { columnName: 'termInfo', width: min ? '110px' : '13%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'shortDesc', width: min ? '100px' : '13%', wordWrapEnabled: true, minWidth: '100px' },
            {
                columnName: 'classPrice',
                width: min ? '120px' : '14%',
                align: 'center',
                wordWrapEnabled: true,
                minWidth: '120px',
            },
            {
                columnName: 'Action',
                width: min ? '110px' : '17%',
                minWidth: '110px',
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={classes.data} columns={columns} rowSelection={'single'}>
                {classes.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={classes.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState selectedRowIds={selectedRowIds} onSelectionChange={onSelectionChange} />
                <TableSelection
                    highlightRow={true}
                    selectByRowClick={true}
                    showSelectAll={false}
                    rowComponent={TableRow}
                    showSelectionColumn={false}
                    rowSelection={'single'}
                />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default ClassGridData
