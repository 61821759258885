import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Divider from '@mui/material/Divider'
import { useDispatch, useSelector } from 'react-redux'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, Label } from 'devextreme-react/form'
import { ColCountByScreen } from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { getUserTypeLookupList } from 'app/redux/store/features/users'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import CustomStore from 'devextreme/data/custom_store'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'

const UpdateUserForm = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { handleSubmit } = useForm()
    const { userTypes } = useSelector((state) => state.users)
    const { loading } = useSelector((state) => state.users)
    const [resetPasswordData, setResetPasswordData] = useState({
        password: '',
        verifyPassword: '',
    })

    const handlePasswordChange = useCallback((e, fieldName) => {
        setResetPasswordData((prevState) => ({
            ...prevState,
            [fieldName]: e.value,
        }))
    }, [])

    useEffect(() => {
        if (userTypes.length <= 0) dispatch(getUserTypeLookupList(''))
    }, [])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const userTypeStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: userTypes,
            totalCount: userTypes.length,
        }),
        reshapeOnPush: true,
    })

    const userTypeLookupEditorOptions = {
        dataSource: userTypeStore,
        readOnly: true,
        displayExpr: 'typeName',
        valueExpr: 'id',
        searchEnabled: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/security/users', { state: { navigateFrom: 'cancel-update-user' } }),
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        lastName: [{ type: 'required', message: 'Last Name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Enter a valid email.' },
        ],
        password: resetPasswordData.password ? [{ type: 'required', message: 'Password is required.' }] : [],
        verifyPassword: resetPasswordData.verifyPassword
            ? [
                  { type: 'required', message: 'Verify Password is required.' },
                  {
                      type: 'custom',
                      validationCallback: (e) => (resetPasswordData.password ? !!e.value : true),
                      message: 'Please enter password and verify password',
                  },
                  {
                      type: 'custom',
                      validationCallback: (e) =>
                          resetPasswordData.password ? e.value === resetPasswordData.password : true,
                      message: 'Password and Verify Password do not match.',
                  },
              ]
            : [],
    }

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await customerLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return customerLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const customerEditorOptions = {
        dataSource: customerLookupsStore,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update User
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form
                    onSubmit={handleSubmit((data) => {
                        if (resetPasswordData.password && !resetPasswordData.verifyPassword) {
                            enqueueSnackbar('Verify Password must be entered if Password is provided.', {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                            })
                            return
                        }
                        if (
                            resetPasswordData.password &&
                            resetPasswordData.password !== resetPasswordData.verifyPassword
                        ) {
                            enqueueSnackbar('Password and Verify Password do not match.', {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                            })
                            return
                        }
                        props.updateUser(data)
                    })}>
                    <Form
                        mode='form'
                        formData={props.userData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem>
                            <GroupItem caption='Update User' cssClass='form-group-item'>
                                <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem cssClass='form-group-item' colSpan={6}>
                                    <EmptyItem />
                                    <GroupItem>
                                        <SimpleItem
                                            dataField='userType'
                                            editorType='dxSelectBox'
                                            editorOptions={userTypeLookupEditorOptions}
                                        />
                                        <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                        <SimpleItem dataField='lastName' validationRules={validationRules.lastName} />
                                        <SimpleItem dataField='email' validationRules={validationRules.email} />
                                        <SimpleItem
                                            dataField='password'
                                            editorType='dxTextBox'
                                            validationRules={validationRules.password}
                                            editorOptions={{
                                                mode: 'password',
                                                value: resetPasswordData.password,
                                                onValueChanged: (e) => handlePasswordChange(e, 'password'),
                                                validationMessageMode: 'always',
                                            }}
                                        />
                                        <SimpleItem
                                            dataField='verifyPassword'
                                            editorType='dxTextBox'
                                            validationRules={validationRules.verifyPassword}
                                            editorOptions={{
                                                mode: 'password',
                                                value: resetPasswordData.verifyPassword,
                                                onValueChanged: (e) => handlePasswordChange(e, 'verifyPassword'),
                                                validationMessageMode: 'always',
                                            }}
                                        />
                                        <SimpleItem dataField='comments' editorType='dxTextArea' />
                                        <SimpleItem dataField='birthDate' editorType='dxDateBox' />
                                        <EmptyItem />
                                        <GroupItem>
                                            <Divider />
                                        </GroupItem>
                                        <EmptyItem />
                                        <SimpleItem
                                            dataField='customerId'
                                            editorType='dxSelectBox'
                                            editorOptions={customerEditorOptions}>
                                            <Label text='Linked Customer' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateUserForm
