import React, { useEffect } from 'react'
import Div from '@jumbo/shared/Div'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import { Grid, useMediaQuery } from '@mui/material'
import TermGrid from './components/termSidebar/TermGrid'
import ClassGrid from './components/classes/ClassGrid'
import SectionGrid from './components/sections/SectionGrid'
import { getTermList } from '../../../../../redux/store/features/terms'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import utilServices from '../../../../../services/util-services'
import { useJumboTheme } from '@jumbo/hooks'

const Classes = () => {
    const pageSize = 25
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { terms } = useSelector((state) => state.terms)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        // Navigate from will be true when we come back from Add class or add section we don't want to reload
        if (utilServices.isNullOrUndefined(state?.navigateFrom) || !terms || !terms.data || terms.data.length <= 0)
            dispatch(getTermList({ skip: 0, take: pageSize }))
    }, [])

    return (
        <Div>
            {min ? (
                // Mobile layout: Stack FamilyGrid and MemberGrid vertically
                <Grid container direction='column' spacing={2}>
                    <Grid item sx={{ width: '100%' }}>
                        <TermGrid />
                    </Grid>
                    <Grid item sx={{ width: '100%' }}>
                        <ClassGrid />
                    </Grid>
                    <Grid item sx={{ width: '100%' }}>
                        <SectionGrid />
                    </Grid>
                </Grid>
            ) : (
                // Desktop layout: Sidebar with FamilyGrid and MemberGrid in content area
                <JumboContentLayout
                    sidebar={
                        <Grid>
                            <TermGrid />
                        </Grid>
                    }
                    layoutOptions={{
                        sidebar: {
                            sx: {
                                mr: 2,
                                width: '29%',
                            },
                        },
                    }}>
                    <Div>
                        <Grid
                            sx={{
                                mb: 1.5,
                            }}>
                            <ClassGrid />
                        </Grid>
                        <Grid>
                            <SectionGrid />
                        </Grid>
                    </Div>
                </JumboContentLayout>
            )}
        </Div>
    )
}

export default Classes
