import { configureStore } from '@reduxjs/toolkit'

import configReducer from './features/configs'
import bannerReducer from './features/banners'
import infobarReducer from './features/infobars'
import notifybarReducer from './features/notifybars'
import classesReducer from './features/classes'
import classTypesReducer from './features/classTypes'
import customersReducer from './features/customers'
import enrolmentsReducer from './features/enrolments'
import placementsReducer from './features/placements'
import familiesReducer from './features/families'
import locationsReducer from './features/locations'
import membersReducer from './features/members'
import permissionsReducer from './features/permissions'
import reportsReducer from './features/reports'
import sectionsReducer from './features/sections'
import teachersReducer from './features/teachers'
import termsReducer from './features/terms'
import usersReducer from './features/users'
import profileReducer from './features/profile'
import dashboardReducer from './features/dashboard'
import ageGroupsReducer from './features/ageGroups'
import transactionReducer from './features/transactions'
import paymentsReducer from './features/payments'

import api from './middleware/api'

const store = configureStore({
    reducer: {
        configs: configReducer,
        banners: bannerReducer,
        infobars: infobarReducer,
        notifybars: notifybarReducer,
        dashboard: dashboardReducer,
        classes: classesReducer,
        classTypes: classTypesReducer,
        customers: customersReducer,
        enrolments: enrolmentsReducer,
        placements: placementsReducer,
        families: familiesReducer,
        locations: locationsReducer,
        members: membersReducer,
        permissions: permissionsReducer,
        reports: reportsReducer,
        sections: sectionsReducer,
        teachers: teachersReducer,
        terms: termsReducer,
        users: usersReducer,
        profile: profileReducer,
        ageGroups: ageGroupsReducer,
        transactions: transactionReducer,
        payments: paymentsReducer,
    },
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), api],
})
export default store
