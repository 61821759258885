import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    infobars: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const infobarSlice = createSlice({
    name: 'infobars',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
            state.refreshing = false
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getInfobars: (state, action) => {
            state.infobars = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingInfobarById: (state, action) => {
            const index = state.infobars.data.findIndex((infobar) => infobar.id === action.payload.id)
            if (index >= 0) state.infobars[index] = action.payload
            else {
                state.infobars.data.push(action.payload)
                state.infobars.totalCount++
            }
            state.loading = false
        },
        addNewInfobar: (state, action) => {
            state.infobars.data.push(action.payload)
            state.infobars.totalCount++
            state.loading = false
        },
        updateExistingInfobar: (state, action) => {
            const index = state.infobars.data.findIndex((infobar) => infobar.id === action.payload.id)
            if (index >= 0) state.infobars[index] = action.payload
            state.loading = false
        },
        removeInfobar: (state, action) => {
            const index = state.infobars.data.findIndex((infobar) => infobar.id === action.payload.id)
            if (index >= 0) {
                state.infobars.data.splice(index, 1)
                state.infobars.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getInfobars,
    getExistingInfobarById,
    addNewInfobar,
    updateExistingInfobar,
    removeInfobar,
} = infobarSlice.actions
export default infobarSlice.reducer

export const getInfobarList = (filter, refresh) => {
    let url = `/api/Image/info/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getInfobars.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInfobarById = (filter) => {
    let url = `/api/Image/info/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingInfobarById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addInfobar = (infobar, headers) => {
    let url = `/api/Image/info/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: infobar,
        headers: headers,
        onStart: apiRequested.type,
        onSuccess: addNewInfobar.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateInfobar = (infobar) => {
    let url = `/api/Image/info/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: infobar,
        onStart: apiRequested.type,
        onSuccess: updateExistingInfobar.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteInfobar = (infobar) => {
    let url = '/api/Image/info/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: infobar,
        onStart: apiRequested.type,
        onSuccess: removeInfobar.type,
        onError: apiRequestedFailed.type,
    })
}
