import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    ageGroups: { data: [], totalCount: 0 },
    ageGroupLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const ageGroupSlice = createSlice({
    name: 'ageGroups',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getAgeGroups: (state, action) => {
            state.ageGroups = action.payload
            state.loading = false
            state.refreshing = false
        },
        getAgeGroupLookup: (state, action) => {
            state.ageGroupLookups = action.payload
            state.loading = false
        },
        getExistingAgeGroupLookupById: (state, action) => {
            const index = state.ageGroupLookups.findIndex((ageGroup) => ageGroup.id === action.payload.id)
            if (index >= 0) state.ageGroupLookups[index] = action.payload
            else {
                state.ageGroupLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingAgeGroupById: (state, action) => {
            const index = state.ageGroups.data.findIndex((ageGroup) => ageGroup.id === action.payload.id)
            if (index >= 0) state.ageGroups[index] = action.payload
            else {
                state.ageGroups.data.push(action.payload)
                state.ageGroups.totalCount++
            }
            state.loading = false
        },
        addNewAgeGroup: (state, action) => {
            state.ageGroups.data.push(action.payload)
            state.ageGroups.totalCount++
            state.loading = false
        },
        updateExistingAgeGroup: (state, action) => {
            const index = state.ageGroups.data.findIndex((ageGroup) => ageGroup.id === action.payload.id)
            if (index >= 0) state.ageGroups[index] = action.payload
            state.loading = false
        },
        removeAgeGroup: (state, action) => {
            const index = state.ageGroups.data.findIndex((ageGroup) => ageGroup.id === action.payload.id)
            if (index >= 0) {
                state.ageGroups.data.splice(index, 1)
                state.ageGroups.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getAgeGroups,
    getAgeGroupLookup,
    getExistingAgeGroupLookupById,
    getExistingAgeGroupById,
    addNewAgeGroup,
    updateExistingAgeGroup,
    removeAgeGroup,
} = ageGroupSlice.actions
export default ageGroupSlice.reducer

export const getAgeGroupList = (filter, refresh = false) => {
    let url = `/api/Setting/ageGroup/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getAgeGroups.type,
        onError: apiRequestedFailed.type,
    })
}

export const getAgeGroupLookupList = (filter) => {
    let url = filter?.ageGroupName
        ? `/api/Setting/ageGroup/lookup/list?search=${filter.ageGroupName}`
        : `/api/Setting/ageGroup/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getAgeGroupLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getAgeGroupLookupById = (filter) => {
    let url = `/api/Setting/ageGroup/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingAgeGroupLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getAgeGroupById = (filter) => {
    let url = `/api/Setting/ageGroup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingAgeGroupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addAgeGroup = (ageGroup) => {
    let url = `/api/Setting/ageGroup/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: ageGroup,
        onStart: apiRequested.type,
        onSuccess: addNewAgeGroup.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateAgeGroup = (ageGroup) => {
    let url = `/api/Setting/ageGroup/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: ageGroup,
        onStart: apiRequested.type,
        onSuccess: updateExistingAgeGroup.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteAgeGroup = (ageGroup) => {
    let url = '/api/Setting/ageGroup/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: ageGroup,
        onStart: apiRequested.type,
        onSuccess: removeAgeGroup.type,
        onError: apiRequestedFailed.type,
    })
}
