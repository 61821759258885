import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import { getTransactionById, selectItemById } from 'app/redux/store/features/transactions'
import HeaderCard from './components/headerCard/HeaderCard'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { TabContext, TabPanel } from '@mui/lab'
import utilServices from 'app/services/util-services'
import InfoBar from './components/infoBar/InfoBar'
import TxnItemsGrid from './components/txnItems/TxnItemsGrid'
import TxnPaymentGrid from './components/txnPayment/TxnPaymentGrid'

const TxnView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { id } = state
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { transactions, loading, error } = useSelector((state) => state.transactions)
    const transaction = selectItemById(transactions, id)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(transaction.id)) dispatch(getTransactionById({ id }))
    }, [transaction.txnPayments])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const [tabTitle, setTabTitle] = React.useState('items')

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={12}>
                <HeaderCard transaction={transaction} />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
                <InfoBar transaction={transaction} />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
                <Grid sx={{ mt: -2 }}>
                    <TabContext value={tabTitle}>
                        <TabList sx={{ bgcolor: 'divider' }} onChange={(e, newValue) => setTabTitle(newValue)}>
                            <Tab label={'Items'} value={'items'} sx={{ width: '160px' }} />
                            <Tab label={'Payments'} value={'payments'} sx={{ width: '160px' }} />
                        </TabList>
                        <TabPanel
                            value='items'
                            sx={{
                                pt: '0%',
                                pl: '0%',
                                pr: '0%',
                            }}>
                            <TxnItemsGrid id={id} />
                        </TabPanel>
                        <TabPanel
                            value='payments'
                            sx={{
                                pl: '0%',
                                pr: '0%',
                                pt: '0%',
                            }}>
                            <TxnPaymentGrid id={id} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TxnView
