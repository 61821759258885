import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { addCustomer } from 'app/redux/store/features/customers'
import { defaultCustomer } from './defaultCustomer'
import AddCustomerForm from './AddCustomerForm'
const AddCustomer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const [customerData] = useState({ ...defaultCustomer })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const { loading, error } = useSelector((state) => state.customers)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Customer added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/registrations/customers')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddCustomer = async () => {
        setApiCallInProgress(true);
        dispatch(addCustomer({ ...customerData }));
    };
    
    return (
        <React.Fragment>
            <AddCustomerForm customerData={customerData} addCustomer={onAddCustomer} />
        </React.Fragment>
    )
}

export default AddCustomer
