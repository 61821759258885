import jwtAuthAxios from 'app/services/auth/jwtAuth'
const termServices = {}

termServices.getTermLookupList = async (filter) => {
    let url = filter?.termYear
        ? `/api/course/Term/lookup/list?search=${filter.termYear}`
        : `/api/course/Term/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

termServices.getTermLookupById = async (key) => {
    let url = `/api/course/Term/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default termServices
