import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { updateIndicator } from 'app/redux/store/features/configs'
import { getMemberById, selectItemById, updateMember } from 'app/redux/store/features/members'
import UpdateMemberForm from './UpdateMemberForm'

const UpdateMember = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const { state } = useLocation()
    const { familyId, memberId } = state
    const { members, loading: memberLoading, error: memberError } = useSelector((state) => state.members)
    const { familyConfig, loading: familyLoading, error: familyError } = useSelector((state) => state.families)
    const member = selectItemById(members, memberId)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(member.id)) {
            dispatch(getMemberById({ familyId: familyId, id: memberId }))
        }
    }, [familyConfig.familyId])

    useEffect(() => {
        if (!apiCallInProgress || memberLoading || familyLoading) return
        setApiCallInProgress(false)

        if (memberError) handleError(memberError)
        else if (familyError) handleError(familyError)
        else {
            enqueueSnackbar('Member updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            dispatch(updateIndicator({ inProgress: false }))
            navigate('/registrations/families')
        }
    }, [memberLoading, familyLoading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateMember = async () => {
        setApiCallInProgress(true)
        dispatch(updateIndicator({ inProgress: true }))
        dispatch(updateMember({ ...member }))
    }

    return (
        <React.Fragment>
            <UpdateMemberForm memberData={member} updateMember={onUpdateMember} />
        </React.Fragment>
    )
}

export default UpdateMember
