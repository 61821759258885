import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { addEnrolment } from 'app/redux/store/features/enrolments'
import { defaultEnrolment } from './defaultEnrolment'
import AddEnrolmentForm from './AddEnrolmentForm'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const AddEnrolment = () => {
    const JumboAuths = useJumboAuth()
    const currentTermId = JumboAuths.authUser.currentTermId
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [enrolmentData] = useState({ ...defaultEnrolment, termId: currentTermId })
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.enrolments)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Enrolment added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/registrations/enrolments')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddEnrolment = async () => {
        setApiCallInProgress(true)
        dispatch(addEnrolment({ ...enrolmentData }))
    }

    return (
        <React.Fragment>
            <AddEnrolmentForm enrolmentData={enrolmentData} addEnrolment={onAddEnrolment} handleError={handleError} />
        </React.Fragment>
    )
}

export default AddEnrolment
