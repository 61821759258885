import React, { useState } from 'react'
import Div from '@jumbo/shared/Div'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import { TabContext, TabPanel } from '@mui/lab'
import PaymentReportList from '../PaymentReportList'
import EnrolmentReportList from '../EnrolmentReportList'

const ReportAppSideBar = () => {
    const [activeTab, setActiveTab] = useState('reports')

    return (
        <React.Fragment>
            <TabContext value={activeTab}>
                <Div sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant='fullWidth' onChange={(event, newTab) => setActiveTab(newTab)}>
                        <Tab label='Reports' value={'reports'} />
                    </TabList>
                </Div>
                <JumboScrollbar
                    style={{ minHeight: 780 }}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}>
                    <TabPanel value={'reports'} sx={{ p: 0 }}>
                        {<EnrolmentReportList />}
                        {<PaymentReportList />}
                    </TabPanel>
                </JumboScrollbar>
            </TabContext>
        </React.Fragment>
    )
}
export default ReportAppSideBar
