export const defaultClass = {
    canDeposit: false,
    depositAmount: 0,
    className: '',
    subTitle: '',
    description: '',
    description2: '',
    capacity: 1,
    reEnrolDays: 0,
    txnDueDays: 0,
    shortDesc: '',
    newEnrolDays: 0,
    comments: '',
    termId: null,
    classTypeId: null,
    locationId: null,
    teacherId: null,
    ageGroupId: null,
    dateStart: '',
    dateEnd: '',
    createdBy: '',
    registerCode: '',
}
