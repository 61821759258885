import React, { useState } from 'react'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import { useForm } from 'react-hook-form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { changeUserPassword } from 'app/redux/store/features/profile'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

const ChangePasswordForm = () => {
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [resetPasswordData, setResetPasswordData] = useState({
        password: '',
        confirmPassword: '',
    })
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit } = useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handlePasswordChange = (e, fieldName) => {
        setResetPasswordData({
            ...resetPasswordData,
            [fieldName]: e.value,
        })

        if (fieldName === 'password') {
            setPasswordMatch(e.value === resetPasswordData.confirmPassword)
        } else if (fieldName === 'confirmPassword') {
            setPasswordMatch(e.value === resetPasswordData.password)
        }
    }

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onChangeUserPassword = async () => {
        if (!passwordMatch) {
            enqueueSnackbar('Password and Confirm Password do not match.', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            return
        }

        try {
            dispatch(
                changeUserPassword({
                    password: resetPasswordData.password,
                })
            )
            enqueueSnackbar('Password changed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } catch (error) {
            handleError(error)
        }
    }

    const validationRules = {
        password: [
            { type: 'required', message: 'Password is required.' },
            { type: 'stringLength', min: 8, message: 'Password must be at least 8 characters long.' },
        ],
        confirmPassword: [
            { type: 'required', message: 'Confirm Password is required.' },
            {
                type: 'compare',
                comparisonTarget: () => resetPasswordData.password,
                message: 'Password and Confirm Password do not match.',
            },
        ],
    }

    const submitButtonOptions = {
        text: 'Update Password',
        type: 'danger',
        width: 180,
        icon: 'check',
        useSubmitBehavior: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick>
                <form onSubmit={handleSubmit(onChangeUserPassword)}>
                    <Form
                        mode='form'
                        formData={resetPasswordData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Change Password' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <GroupItem>
                                    <SimpleItem
                                        dataField='password'
                                        editorType='dxTextBox'
                                        validationRules={validationRules.password}
                                        editorOptions={{
                                            mode: 'password',
                                            value: resetPasswordData.password,
                                            onValueChanged: (e) => handlePasswordChange(e, 'password'),
                                            validationMessageMode: 'always',
                                        }}
                                    />
                                    <SimpleItem
                                        dataField='confirmPassword'
                                        editorType='dxTextBox'
                                        validationRules={validationRules.confirmPassword}
                                        editorOptions={{
                                            mode: 'password',
                                            value: resetPasswordData.confirmPassword,
                                            onValueChanged: (e) => handlePasswordChange(e, 'confirmPassword'),
                                            validationMessageMode: 'always',
                                        }}
                                    />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={1} md={1} sm={1} xs={1} />
                            <ButtonItem horizontalAlignment='center' buttonOptions={submitButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default ChangePasswordForm
