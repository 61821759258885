import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    teachers: { data: [], totalCount: 0 },
    teacherLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const teacherSlice = createSlice({
    name: 'teachers',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTeachers: (state, action) => {
            state.teachers = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTeacherLookup: (state, action) => {
            state.teacherLookups = action.payload
            state.loading = false
        },
        getExistingTeacherLookupById: (state, action) => {
            const index = state.teacherLookups.findIndex((teacher) => teacher.id === action.payload.id)
            if (index >= 0) state.teacherLookups[index] = action.payload
            else {
                state.teacherLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingTeacherById: (state, action) => {
            const index = state.teachers.data.findIndex((teacher) => teacher.id === action.payload.id)
            if (index >= 0) state.teachers[index] = action.payload
            else {
                state.teachers.data.push(action.payload)
                state.teachers.totalCount++
            }
            state.loading = false
        },
        addNewTeacher: (state, action) => {
            state.teachers.data.push(action.payload)
            state.teachers.totalCount++
            state.loading = false
        },
        updateExistingTeacher: (state, action) => {
            const index = state.teachers.data.findIndex((teacher) => teacher.id === action.payload.id)
            if (index >= 0) state.teachers[index] = action.payload
            state.loading = false
        },
        removeTeacher: (state, action) => {
            const index = state.teachers.data.findIndex((teacher) => teacher.id === action.payload.id)
            if (index >= 0) {
                state.teachers.data.splice(index, 1)
                state.teachers.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getTeachers,
    getTeacherLookup,
    getExistingTeacherLookupById,
    getExistingTeacherById,
    addNewTeacher,
    updateExistingTeacher,
    removeTeacher,
} = teacherSlice.actions
export default teacherSlice.reducer

export const getTeacherList = (filter, refresh = false) => {
    return apiCallBegan({
        url: '/api/Teacher/list',
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getTeachers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTeacherLookupList = (filter) => {
    let url = filter?.firstName ? `/api/Teacher/lookup/list?search=${filter.firstName}` : `/api/Teacher/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTeacherLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTeacherLookupById = (filter) => {
    let url = `/api/Teacher/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTeacherLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTeacherById = (filter) => {
    let url = `/api/Teacher/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTeacherById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addTeacher = (teacher) => {
    let url = `/api/Teacher/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: teacher,
        onStart: apiRequested.type,
        onSuccess: addNewTeacher.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTeacher = (teacher) => {
    let url = `/api/Teacher/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: teacher,
        onStart: apiRequested.type,
        onSuccess: updateExistingTeacher.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteTeacher = (teacher) => {
    let url = '/api/Teacher/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: teacher,
        onStart: apiRequested.type,
        onSuccess: removeTeacher.type,
        onError: apiRequestedFailed.type,
    })
}
