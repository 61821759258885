export const defaultMember = {
    familyId: null,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    GenderType: 0,
    relationshipType: 0,
    birthDate: null,
    isPrimary: false,
}
