import React from 'react'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import HeaderCardList from './components/HeaderCardList'

const HeaderCard = () => {
    const { courseEnrolments } = useSelector((state) => state.dashboard)

    return (
        <Grid container spacing={1.5}>
            {courseEnrolments.map((item, index) => (
                <Grid item xs={12} md={6} lg={3} key={index}>
                    <HeaderCardList item={item} />
                </Grid>
            ))}
        </Grid>
    )
}

export default HeaderCard
