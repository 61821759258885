import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import Typography from '@mui/material/Typography'
import { TabContext, TabPanel } from '@mui/lab'
import TermGrid from './components/terms/TermGrid'
import ClassGrid from './components/classes/Classes'

function Setup() {
    const navigate = useNavigate()
    const { selectedTab } = useParams()
    const [tabTitle, setTabTitle] = React.useState(selectedTab ? selectedTab : 'terms')

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
        navigate(`/courses/setup/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Setup
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList
                        sx={{ bgcolor: 'background.default', mt: 2, mb: 0, ml: 3.1, mr: 3.1 }}
                        onChange={onTabSelectionChanged}>
                        <Tab label={'Terms'} value={'terms'} sx={{ width: '160px', textTransform: 'capitalize' }} />
                        <Tab label={'Classes'} value={'classes'} sx={{ width: '160px', textTransform: 'capitalize' }} />
                    </TabList>
                    <TabPanel value='terms'>
                        <TermGrid />
                    </TabPanel>
                    <TabPanel value='classes'>
                        <ClassGrid />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default Setup
