import React from 'react'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import '../../../../../../../../constants/stylesheet.css'

const ChangeDetailImageUpload = (props) => {
    const isSubmitDisabled = !!props.detailImage.filePath
    // Options for the 'Add' button
    const submitButtonOptionsDetail = {
        text: 'Add',
        type: 'default',
        width: 120,
        icon: 'check',
        useSubmitBehavior: true,
        disabled: isSubmitDisabled,
    }

    // Options for the 'Delete' button
    const deleteButtonOptionsDetail = {
        text: 'Delete',
        type: 'danger',
        width: 120,
        icon: 'trash',
        useSubmitBehavior: false,
        onClick: props.onRemoveDetailImage,
        disabled: !props.filePathDetailData,
    }

    const validationRules = {
        file: [
            { type: 'required', message: 'Image is required.' },
            {
                type: 'custom',
                validationCallback: (e) => e.value[0]?.size <= 256 * 1024,
                message: 'Image should not be more than 256 KB.',
            },
        ],
    }

    return (
        <React.Fragment>
            <form action='' onSubmit={props.onAddDetailImage}>
                <Form
                    mode='form'
                    formData={props.detailImage}
                    showColonAfterLabel={false}
                    showValidationSummary={false}>
                    <GroupItem cssClass='form-group-item'>
                        <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem cssClass='form-group-item' colSpan={6}>
                            <EmptyItem />
                            {!props.filePathDetailData || props.filePathDetailData === '' ? (
                                <SimpleItem
                                    cssClass='imageStyling'
                                    dataField='file'
                                    editorType='dxFileUploader'
                                    validationRules={validationRules.file}
                                    editorOptions={{
                                        uploadMode: 'useForm',
                                        multiple: false,
                                        selectButtonText: 'Select File',
                                        labelText: '',
                                        accept: 'image/*',
                                        allowedFileExtensions: ['.jpg', '.jpeg', '.png'],
                                    }}>
                                    <Label text='Select an Image (1100x1100px)' />
                                </SimpleItem>
                            ) : (
                                <SimpleItem>
                                    <div className='image-container'>
                                        <img src={props.filePathDetailData} alt='Preview' className='image-preview' />
                                    </div>
                                </SimpleItem>
                            )}
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem buttonOptions={submitButtonOptionsDetail} horizontalAlignment='right' />
                        <ButtonItem buttonOptions={deleteButtonOptionsDetail} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default ChangeDetailImageUpload
