import React from 'react'
import JumboLayout from '@jumbo/components/JumboLayout'
import Header from '../shared/headers/Header'
import Sidebar from '../shared/sidebars/Sidebar'
import useJumboLayout from '@jumbo/hooks/useJumboLayout'
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme'
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { headerTheme as defaultTheme } from '../../themes/header/default'
import layoutConfig from './layoutConfig'
import { useMediaQuery } from '@mui/material'
import Div from '@jumbo/shared/Div'

const VerticalDefault = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout()
    const { sidebarOptions } = useJumboLayoutSidebar()
    const { setHeaderTheme } = useJumboHeaderTheme()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig)
    }, [])

    React.useEffect(() => {
        setHeaderTheme({ ...theme, ...defaultTheme })
    }, [sidebarOptions.style])

    return (
        <JumboLayout
            header={<Header theme={theme} />}
            sidebar={<Sidebar />}
            headerSx={{
                height: min ? 60 : 50,
            }}>
            <Div
                style={{
                    marginTop: min ? '0px' : sidebarOptions?.open ? '-3px' : '-3px',
                }}>
                {children}
            </Div>
        </JumboLayout>
    )
}

export default VerticalDefault
