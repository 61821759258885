import React from 'react'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import LockResetIcon from '@mui/icons-material/LockReset'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import EditIcon from '@mui/icons-material/Edit'
import LogoutIcon from '@mui/icons-material/Logout'
import ReportIcon from '@mui/icons-material/Report'
import GroupsIcon from '@mui/icons-material/Groups'
import PeopleIcon from '@mui/icons-material/People'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import { CanViewProfileSettings } from '../../../../constants/Permissions'
import { CanViewSecurity } from '../../../../constants/Permissions'
import { CanViewCourses } from '../../../../constants/Permissions'
import { CanViewRegistrations } from '../../../../constants/Permissions'
import { CanViewMessages } from '../../../../constants/Permissions'
import { CanViewSettings } from '../../../../constants/Permissions'
import { CanViewReports } from '../../../../constants/Permissions'
import util from '../../../../utils/permissions/util-permissions'

const profile = (authSlice) => {
    let profileMenus = []
    let permission = util.hasPermission(authSlice, CanViewProfileSettings)
    if (permission) {
        profileMenus.push({
            uri: '/profile/editprofile',
            label: 'sidebar.menuItem.profile',
            type: 'nav-item',
            icon: <EditIcon sx={{ fontSize: 20 }} />,
        })
    }
    profileMenus.push({
        uri: '/profile/signout',
        label: 'sidebar.menuItem.logout',
        type: 'nav-item',
        icon: <LogoutIcon sx={{ fontSize: 20 }} />,
    })
    return profileMenus
}

export const getSidebarMenus = (authSlice) => {
    let smenu = []
    if (authSlice && authSlice.permissions) {
        smenu.push({
            label: 'sidebar.menu.home',
            type: 'section',
            children: [
                {
                    uri: '/home/dashboard',
                    label: 'sidebar.menuItem.dashboard',
                    type: 'nav-item',
                    icon: <PieChartOutlineOutlinedIcon sx={{ fontSize: 20 }} />,
                },
            ],
        })

        let permission = util.hasPermission(authSlice, CanViewSecurity)
        if (permission) {
            smenu.push({
                label: 'sidebar.menu.security',
                type: 'section',
                children: [
                    {
                        uri: '/security/users',
                        label: 'sidebar.menuItem.users',
                        type: 'nav-item',
                        icon: <PersonAddAltIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/security/teachers',
                        label: 'sidebar.menuItem.teachers',
                        type: 'nav-item',
                        icon: <PeopleIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/security/permissions',
                        label: 'sidebar.menuItem.permissions',
                        type: 'nav-item',
                        icon: <LockResetIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            })
        }

        permission = util.hasPermission(authSlice, CanViewCourses)
        if (permission) {
            smenu.push({
                label: 'sidebar.menu.courses',
                type: 'section',
                children: [
                    {
                        uri: '/courses/setup',
                        label: 'sidebar.menuItem.setup',
                        type: 'nav-item',
                        icon: <NewspaperIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/courses/placement',
                        label: 'sidebar.menuItem.placement',
                        type: 'nav-item',
                        icon: <ContactsOutlinedIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            })
        }

        permission = util.hasPermission(authSlice, CanViewRegistrations)
        if (permission) {
            smenu.push({
                label: 'sidebar.menu.registrations',
                type: 'section',
                children: [
                    {
                        uri: '/registrations/customers',
                        label: 'sidebar.menuItem.customers',
                        type: 'nav-item',
                        icon: <GroupsIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/registrations/families',
                        label: 'sidebar.menuItem.families',
                        type: 'nav-item',
                        icon: <FamilyRestroomIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/registrations/transactions',
                        label: 'sidebar.menuItem.transactions',
                        type: 'nav-item',
                        icon: <ShoppingCartOutlinedIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/registrations/payments',
                        label: 'sidebar.menuItem.payments',
                        type: 'nav-item',
                        icon: <CreditCardIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/registrations/enrolments',
                        label: 'sidebar.menuItem.enrolments',
                        type: 'nav-item',
                        icon: <PersonAddAltIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            })
        }

        permission = util.hasPermission(authSlice, CanViewMessages)
        if (permission) {
            smenu.push({
                label: 'sidebar.menu.messages',
                type: 'section',
                children: [
                    {
                        uri: '/messages/chat',
                        label: 'sidebar.menuItem.chat',
                        type: 'nav-item',
                        icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            })
        }

        permission = util.hasPermission(authSlice, CanViewSettings)
        if (permission) {
            smenu.push({
                label: 'sidebar.menu.settings',
                type: 'section',
                children: [
                    {
                        uri: '/settings/configuration',
                        label: 'sidebar.menuItem.configuration',
                        type: 'nav-item',
                        icon: <WidgetsOutlinedIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: '/settings/definitions',
                        label: 'sidebar.menuItem.definitions',
                        type: 'nav-item',
                        icon: <AddBusinessIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            })
        }

        permission = util.hasPermission(authSlice, CanViewReports)
        if (permission) {
            smenu.push({
                label: 'sidebar.menu.reports',
                type: 'section',
                children: [
                    {
                        uri: '/reports',
                        label: 'sidebar.menuItem.reports',
                        type: 'nav-item',
                        icon: <ReportIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            })
        }

        smenu.push({
            label: 'sidebar.menu.profile',
            type: 'section',
            children: profile(authSlice),
        })
    }
    return smenu
}
