import jwtAuthAxios from 'app/services/auth/jwtAuth'

const ageGroupService = {}

ageGroupService.getAgeGroupLookupList = async (filter) => {
    let url = filter?.ageGroupName
        ? `/api/Setting/ageGroup/lookup/list?search=${filter.ageGroupName}`
        : `/api/Setting/ageGroup/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw Error(error.response.data)
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

ageGroupService.getAgeGroupLookupById = async (key) => {
    let url = `/api/Setting/ageGroup/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw Error(error.response.data)
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default ageGroupService
