import React from 'react'
import FamilyGrid from '../pages/registrations/family/Family'
import AddFamily from '../pages/registrations/family/components/familyGridSidebar/components/AddFamily'
import UpdateFamily from '../pages/registrations/family/components/familyGridSidebar/components/UpdateFamily'
import AddMember from '../pages/registrations/family/components/members/components/AddMember'
import UpdateMember from '../pages/registrations/family/components/members/components/UpdateMember'
import Transactions from '../pages/registrations/transactions'
import CustomersGrid from 'app/pages/registrations/customers/CustomersGrid'
import EnrolmentGrid from 'app/pages/registrations/enrolments/EnrolmentGrid'
import AddEnrolment from '../pages/registrations/enrolments/components/AddEnrolment'
import UpdateEnrolment from '../pages/registrations/enrolments/components/UpdateEnrolment'
import Page from '@jumbo/shared/Page'
import AddCustomer from 'app/pages/registrations/customers/components/AddCustomer'
import UpdateCustomer from 'app/pages/registrations/customers/components/UpdateCustomer'
import PaymentsGrid from 'app/pages/registrations/payments/PaymentsGrid'
import TxnView from 'app/pages/registrations/transactions/components/txnView/TxnView'

const registrationRoutes = [
    {
        path: '/registrations/families',
        element: <Page component={FamilyGrid} />,
    },
    {
        path: '/registrations/families/add',
        element: <Page component={AddFamily} />,
    },
    {
        path: '/registrations/families/edit/:id',
        element: <Page component={UpdateFamily} />,
    },
    {
        path: '/registrations/families/member/add/:id',
        element: <Page component={AddMember} />,
    },
    {
        path: '/registrations/families/:id/member/edit/:memberId',
        element: <Page component={UpdateMember} />,
    },
    {
        path: '/registrations/transactions',
        element: <Page component={Transactions} />,
    },

    {
        path: `/registrations/transactions/details/:id`,
        element: <Page component={TxnView} />,
    },
    {
        path: '/registrations/payments',
        element: <Page component={PaymentsGrid} />,
    },
    {
        path: '/registrations/customers',
        element: <Page component={CustomersGrid} />,
    },
    {
        path: '/registrations/customers/addcustomer',
        element: <Page component={AddCustomer} />,
    },
    {
        path: '/registrations/customers/edit/:customerId',
        element: <Page component={UpdateCustomer} />,
    },

    {
        path: ['/registrations/enrolments'],
        element: <Page component={EnrolmentGrid} />,
    },
    {
        path: '/registrations/enrolments/add',
        element: <Page component={AddEnrolment} />,
    },
    {
        path: '/registrations/enrolments/edit/:enrolmentId',
        element: <Page component={UpdateEnrolment} />,
    },
]

export default registrationRoutes
