import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { SelectBox } from 'devextreme-react/select-box'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'
import { getSectionLookupList } from 'app/redux/store/features/sections'
import { getTeacherLookupList } from 'app/redux/store/features/teachers'
const ClassGridSidebarFilter = ({ mutation, handleError }) => {
    const dispatch = useDispatch()
    const [sectionData, setSectionData] = useState('')
    const [teacherData, setTeacherData] = useState('')

    const { classConfig, loading: classLoading, error: classError } = useSelector((state) => state.classes)
    const { sectionLookups, loading: sectionLoading, error: sectionError } = useSelector((state) => state.sections)
    const { teacherLookups, loading: teacherLoading, error: teacherError } = useSelector((state) => state.teachers)

    useEffect(() => {
        const error = sectionError || teacherError || classError
        if (error) handleError()
    }, [classLoading, sectionLoading, teacherLoading])

    useEffect(() => {
        dispatch(getTeacherLookupList(''))
    }, [])

    // useEffect(() => {
    //     dispatch(getSectionLookupList({ classId: classConfig.classId }))
    // }, [classConfig.classId])

    const sectionLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: sectionLookups,
            totalCount: sectionLookups.length,
        }),
        reshapeOnPush: true,
    })
    const teacherLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: teacherLookups,
            totalCount: teacherLookups.length,
        }),
        reshapeOnPush: true,
    })
    const onFormSubmit = () => {
        mutation.mutate({
            sectionName: sectionData ? sectionData.sectionName : '',
            teacherFirstName: teacherData ? teacherData.firstName : '',
        })
    }
    return (
        <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ borderRadius: 0 }}>
            <Div style={{ display: 'flex', margin: '0 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                <Div sx={{ mr: 3, mt: 1.5 }}>
                    <Div>
                        <Typography>Section Name</Typography>
                    </Div>
                    <Div sx={{ mt: 0.5 }}>
                        <SelectBox
                            style={{ width: 210 }}
                            dataSource={sectionLookupsStore}
                            displayExpr={'sectionName'}
                            value={sectionData}
                            searchEnabled={true}
                            onValueChange={setSectionData}
                            showClearButton={true}
                        />
                    </Div>
                </Div>
                <Div sx={{ mr: 3, mt: 1.5 }}>
                    <Div>
                        <Typography>Teacher Name</Typography>
                    </Div>
                    <Div sx={{ mt: 0.5 }}>
                        <SelectBox
                            style={{ width: 210 }}
                            dataSource={teacherLookupsStore}
                            itemTemplate={(data) => data.firstName + ' ' + data.lastName}
                            displayExpr={(data) => (data ? data.firstName + ' ' + data.lastName : '')}
                            value={teacherData}
                            searchEnabled={true}
                            onValueChange={setTeacherData}
                            showClearButton={true}
                        />
                    </Div>
                </Div>
                <Div sx={{ mt: 4.5 }}>
                    <SearchIcon
                        size={'large'}
                        fontSize={'large'}
                        color={'success'}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                        onClick={onFormSubmit}
                    />
                </Div>
            </Div>
        </JumboCardQuick>
    )
}

export default ClassGridSidebarFilter
