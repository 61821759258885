import React from 'react'
import Div from '@jumbo/shared/Div'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid, useMediaQuery } from '@mui/material'
import FamilyGrid from './components/familyGridSidebar/FamilyGrid'
import MemberGrid from './components/members/MemberGrid'
import { useJumboTheme } from '@jumbo/hooks'

const FamilyGridLayout = () => {
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pt: 3.2,
            }}>
            <Div sx={{ m: 1 }}>
                {min ? (
                    // Mobile layout: Stack FamilyGrid and MemberGrid vertically
                    <Grid container direction='column' spacing={2}>
                        <Grid item sx={{ width: '100%' }}>
                            <FamilyGrid />
                        </Grid>
                        <Grid item sx={{ width: '100%' }}>
                            <MemberGrid />
                        </Grid>
                    </Grid>
                ) : (
                    // Desktop layout: Sidebar with FamilyGrid and MemberGrid in content area
                    <JumboContentLayout
                        sidebar={
                            <Grid>
                                <FamilyGrid />
                            </Grid>
                        }
                        layoutOptions={{
                            sidebar: {
                                sx: {
                                    mr: 1,
                                    width: '30%',
                                },
                            },
                        }}>
                        <Grid
                            sx={{
                                mb: 1.5,
                            }}>
                            <MemberGrid />
                        </Grid>
                    </JumboContentLayout>
                )}
            </Div>
        </JumboCardQuick>
    )
}

export default FamilyGridLayout
