import React from 'react'
import { useNavigate } from 'react-router-dom'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const SignOut = () => {
    const navigate = useNavigate()
    const { setAuthToken } = useJumboAuth()

    React.useEffect(() => {
        setAuthToken(null)

        navigate('/signin')
    }, [setAuthToken, navigate])

    return null
}
export default SignOut
