import React from 'react'
import Setup from '../pages/courses/setup'
import AddTerm from '../pages/courses/setup/components/terms/components/AddTerm'
import UpdateTerm from '../pages/courses/setup/components/terms/components/UpdateTerm'
import AddClass from '../pages/courses/setup/components/classes/components/classes/components/AddClass'
import UpdateClass from '../pages/courses/setup/components/classes/components/classes/components/UpdateClass'
import UpdateSection from '../pages/courses/setup/components/classes/components/sections/components/UpdateSection'
import AddSection from '../pages/courses/setup/components/classes/components/sections/components/AddSection'

import PlacementGrid from '../pages/courses/placement'
import Page from '@jumbo/shared/Page'

const coursesRoutes = [
    {
        path: ['/courses/setup', '/courses/setup/:selectedTab'],
        element: <Page component={Setup} />,
    },
    {
        path: ['/courses/setup/term/add'],
        element: <Page component={AddTerm} />,
    },
    {
        path: ['/courses/setup/term/edit/:termId'],
        element: <Page component={UpdateTerm} />,
    },
    {
        path: ['/courses/setup/classes/add/:id'],
        element: <Page component={AddClass} />,
    },
    {
        path: ['/courses/setup/classes/edit/:classId'],
        element: <Page component={UpdateClass} />,
    },
    {
        path: ['/courses/setup/classes/section/add/:classId'],
        element: <Page component={AddSection} />,
    },
    {
        path: ['/courses/setup/classes/:classId/section/edit/:sectionId'],
        element: <Page component={UpdateSection} />,
    },
    {
        path: '/courses/placement',
        element: <Page component={PlacementGrid} />,
    },
]

export default coursesRoutes
