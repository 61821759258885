import React from 'react'
import { Typography } from '@mui/material'
import Div from '@jumbo/shared/Div'

const ContentPlaceholder = () => {
    return (
        <Div
            sx={{
                textAlign: 'center',
                margin: 'auto',
                p: 3,
            }}>
            <Typography variant={'h2'} mt={2} color={'text.primary'}>
                Welcome to report app, <br />
                Please fill me with a nice image and some intro.
            </Typography>
        </Div>
    )
}

export default ContentPlaceholder
