import jwtAuthAxios from 'app/services/auth/jwtAuth'

const sectionService = {}

sectionService.getSectionLookupList = async (filter) => {
    let url = filter?.sectionName
        ? `/api/Class/${filter.classId}/section/lookup/list?search=${filter.sectionName}`
        : `/api/Class/${filter.classId}/section/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

sectionService.getSectionLookupById = async (classId, key) => {
    let url = `/api/Class/${classId}/section/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default sectionService
