import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile } from 'app/redux/store/features/profile'
import { PatternRule } from 'devextreme-react/data-grid'

const EditProfileForm = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit } = useForm()
    const { profile, loading } = useSelector((state) => state.profile)

    useEffect(() => {
        dispatch(getUserProfile())
    }, [])

    const [profileState, setProfileState] = useState({ ...profile })

    useEffect(() => {
        setProfileState({ ...profile })
    }, [profile])

    const saveButtonOptions = {
        text: 'Save Changes',
        type: 'danger',
        width: 180,
        useSubmitBehavior: true,
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        lastName: [{ type: 'required', message: 'Last Name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Enter a valid email.' },
        ],
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }

    const phonePattern = /^\+[1-9]\d{10,14}$/

    const onSubmit = async () => {
        props.updateProfile(profileState)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Profile Settings
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form mode='form' formData={profileState} showColonAfterLabel={false} showValidationSummary={false}>
                        <GroupItem caption='Edit Profile' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <GroupItem>
                                    <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                    <SimpleItem dataField='lastName' validationRules={validationRules.lastName} />
                                    <SimpleItem dataField='email' validationRules={validationRules.email} />
                                    <SimpleItem
                                        dataField='phoneNumber'
                                        editorOptions={phoneEditorOptions}
                                        validationRules={[{ type: 'required', message: 'Phone Number is required.' }]}>
                                        <Label text='Phone Number' />
                                        <PatternRule
                                            message='phone number must start with + and needs to include country code'
                                            pattern={phonePattern}
                                        />
                                    </SimpleItem>
                                    <SimpleItem dataField='birthDate' editorType='dxDateBox' />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={1} md={1} sm={1} xs={1} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...saveButtonOptions,
                                }}
                                horizontalAlignment='center'
                            />
                        </GroupItem>
                        <EmptyItem />
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default EditProfileForm
