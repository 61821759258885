import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    families: { data: [], totalCount: 0 },
    familyLookups: [],
    familyConfig: { familyId: null },
    loading: false,
    error: null,
    refreshing: false,
}

const familySlice = createSlice({
    name: 'families',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getFamilyConfig: (state, action) => {
            state.familyConfig = action.payload
        },
        updateFamilyConfig: (state, action) => {
            state.familyConfig = action.payload
        },
        getFamilies: (state, action) => {
            state.families = action.payload
            state.loading = false
            state.refreshing = false
        },
        getFamilyLookup: (state, action) => {
            state.familyLookups = action.payload
            state.loading = false
        },
        getExistingFamilyLookupById: (state, action) => {
            const index = state.familyLookups.findIndex((family) => family.id === action.payload.id)
            if (index >= 0) state.familyLookups[index] = action.payload
            else {
                state.familyLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingFamilyById: (state, action) => {
            const index = state.families.data.findIndex((family) => family.id === action.payload.id)
            if (index >= 0) state.families[index] = action.payload
            else {
                state.families.data.push(action.payload)
                state.families.totalCount++
            }
            state.loading = false
        },
        addNewFamily: (state, action) => {
            state.families.data.push(action.payload)
            state.families.totalCount++
            state.loading = false
        },
        updateExistingFamily: (state, action) => {
            const index = state.families.data.findIndex((family) => family.id === action.payload.id)
            if (index >= 0) state.families[index] = action.payload
            state.loading = false
        },
        removeFamily: (state, action) => {
            const index = state.families.data.findIndex((family) => family.id === action.payload.id)
            if (index >= 0) {
                state.families.data.splice(index, 1)
                state.families.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getFamilyConfig,
    updateFamilyConfig,
    getFamilies,
    getFamilyLookup,
    getExistingFamilyLookupById,
    getExistingFamilyById,
    addNewFamily,
    updateExistingFamily,
    removeFamily,
} = familySlice.actions
export default familySlice.reducer

export const getFamilyList = (filter, refresh = false) => {
    let url = `/api/Family/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getFamilies.type,
        onError: apiRequestedFailed.type,
    })
}

export const getFamilyLookupList = (filter) => {
    let url = filter?.familyName ? `/api/Family/lookup/list?search=${filter.familyName}` : `/api/Family/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getFamilyLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getFamilyLookupById = (filter) => {
    let url = `/api/Family/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingFamilyLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getFamilyById = (filter) => {
    let url = `/api/Family/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingFamilyById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addFamily = (family) => {
    let url = `/api/Family/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: family,
        onStart: apiRequested.type,
        onSuccess: addNewFamily.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateFamily = (family) => {
    let url = `/api/Family/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: family,
        onStart: apiRequested.type,
        onSuccess: updateExistingFamily.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteFamily = (family) => {
    let url = '/api/Family/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: family,
        onStart: apiRequested.type,
        onSuccess: removeFamily.type,
        onError: apiRequestedFailed.type,
    })
}
