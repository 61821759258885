import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AddIcon from '@mui/icons-material/Add'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getFamilyList } from 'app/redux/store/features/families'
import FamilyGridFilter from './components/FamilyGridFilter'
import FamilyGridData from './components/FamilyGridData'

const FamilyGrid = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({
        familyName: '',
        firstName: '',
        lastName: '',
    })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getFamilyList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function userReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [familyGrid, setFamilyGrid] = React.useReducer(
        userReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getFamilyList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getFamilyList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getFamilyList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getFamilyList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setFamilyGrid({ type: 'set-grid-filter', payload: value })
        },
        [setFamilyGrid]
    )

    const onAddFamily = () => {
        navigate(`/registrations/families/add`)
    }

    const setFilterIconColor = React.useCallback(
        (value) => {
            setFamilyGrid({ type: 'set-filter-icon-color', payload: value })
        },
        [setFamilyGrid]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.2,
                            ml: -2,
                            mr: 0.5,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Family List
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        fontSize={'large'}
                        variant={'contained'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={onAddFamily}
                    />
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            mt: -1.8,
                            color:
                                familyGrid.filterIconColor && !familyGrid.gridFilterVisible
                                    ? 'success.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!familyGrid.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <FamilyGridFilter
                    mutation={applyFilter}
                    display={familyGrid.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Grid>
                <FamilyGridData
                    onRefresh={onRefresh}
                    onPageChange={onPageChange}
                    onColumnSort={onColumnSort}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    apiCallInProgress={apiCallInProgress}
                    setApiCallInProgress={setApiCallInProgress}
                />
            </Grid>
        </JumboCardQuick>
    )
}
export default FamilyGrid
