let GenderTypeService = {}

GenderTypeService.setGenderTypeList = [
    { id: 0, genderType: '' },
    { id: 1, genderType: 'Male' },
    { id: 2, genderType: 'Female' },
]

GenderTypeService.getGenderType = () => {
    return GenderTypeService.setGenderTypeList
}

GenderTypeService.getGenderTypeData = (row) => {
    const genderType = GenderTypeService.setGenderTypeList.find((item) => item.id === row.genderType)
    return genderType ? genderType.genderType : ''
}

export default GenderTypeService
