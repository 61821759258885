import React from 'react'
import Div from '@jumbo/shared/Div'
import useReportApp from '../../../hooks/useReportApp'
import EnrolmentListReport from '../../EnrolmentReportList/components/enrolmentlistreport/EnrolmentListReport'
import PaymentListReport from '../../PaymentReportList/components/paymentlistreport/PaymentListReport'
const ActiveReportView = () => {
    const { activeReport } = useReportApp()

    const salesReportList = {
        enrolmentlistreport: EnrolmentListReport,
    }

    const paymentReportList = {
        paymentlistreport: PaymentListReport,
    }
    if (activeReport?.category === 'enrolment') {
        return <Div>{React.createElement(salesReportList[activeReport.id.replace(/-/g, '')])}</Div>
    } else if (activeReport?.category === 'payment') {
        return <Div>{React.createElement(paymentReportList[activeReport.id.replace(/-/g, '')])};</Div>
    } else {
        return <Div />
    }
}

export default ActiveReportView
