import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'

const useIdleTimeout = ({ onPrompt, onIdleExpired, idleTimeInMin = 10 }) => {
    const idleTimeout = 1000 * 60 * idleTimeInMin

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: idleTimeout / 5,
        onPrompt: onPrompt,
        onIdle: onIdleExpired,
        debounce: 500,
    })
    return { idleTimer }
}
export default useIdleTimeout
