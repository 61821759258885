import React from 'react'
import { Navigate } from 'react-router-dom'
import SignIn from '../pages/login'
import Page from '@jumbo/shared/Page'
import dashboardRoutes from './dashboardRoutes'
import securityRoutes from './securityRoutes'
import coursesRoutes from './coursesRoutes'
import registrationRoutes from './registrationRoutes'
import messagesRoutes from './messagesRoutes'
import settingRoutes from './settingRoutes'
import reportRoutes from './reportRoutes'
import profileRoutes from './profileRoutes'
import appsRoutes from './appsRoutes'

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = []

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: '/',
        element: <Navigate to={'/home/dashboard'} />,
    },
    ...appsRoutes,
    ...dashboardRoutes,
    ...securityRoutes,
    ...coursesRoutes,
    ...registrationRoutes,
    ...messagesRoutes,
    ...settingRoutes,
    ...reportRoutes,
    ...profileRoutes,
]

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: '/signin',
        element: <Page component={SignIn} layout={'solo-page'} disableSmLogin={true} />,
    },
]

const routes = [...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly]

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly }
