import React from 'react'
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar'
import { IconButton, Stack, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from '@jumbo/utils/constants'
import LoginUserAvatar from './components/LoginUserAvatar'
import Div from '@jumbo/shared/Div'

const Header = ({ theme }) => {
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    return (
        <React.Fragment>
            {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
                sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
                (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)) && (
                <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'
                    sx={{
                        ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                        mr: 3,
                    }}
                    onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}>
                    {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
            )}

            {
                <>
                    {/* <CompanySelection />
                    <BranchSelection /> */}
                    <LoginUserAvatar />
                </>
            }
        </React.Fragment>
    )
}

export default Header
