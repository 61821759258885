import React, { useState, useEffect } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useDispatch } from 'react-redux'
import TxnItemsDataGrid from './TxnItemsDataGrid'
import { Grid } from '@mui/material'
import { getTxnItemList } from 'app/redux/store/features/transactions'

const TxnItemsGrid = ({ id }) => {
    const pageSize = 10
    const [filter, setFilter] = useState({
        txnId: id,
    })
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getTxnItemList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getTxnItemList(currentFilter))
        setFilter(currentFilter)
    }

    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pl: 1,
            }}>
            <Grid>
                <TxnItemsDataGrid
                    onColumnSort={onColumnSort}
                    apiCallInProgress={apiCallInProgress}
                    setApiCallInProgress={setApiCallInProgress}
                />
            </Grid>
        </JumboCardQuick>
    )
}

export default TxnItemsGrid
