import React, { useState, useCallback, useMemo } from 'react'
import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import classesService from 'app/services/pages/courses/setup/classesServices/classesService'

const PaymentListReportFilter = ({ mutation }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [showBalance, setShowBalance] = useState(false)

    let initialValues = {
        sort: '',
    }
    const [filter, setFilter] = useState({ customerId: null })

    const initialFilterFields = {
        termSelectedData: '',
        classSelectedData: '',
    }

    const [filterFields, setFilterFields] = useState({ ...initialFilterFields })

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )
    const onTermValueChanged = async (e) => {
        if (e) {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                termSelectedData: e.id,
            }))
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                termSelectedData: null,
                classSelectedData: null,
            }))
        }
    }

    const onClassValueChanged = (e) => {
        if (e) {
            setFilterFields({
                ...filterFields,
                classSelectedData: e.id,
            })
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                classSelectedData: null,
                sectionSelectedData: null,
            }))
        }
    }

    const termLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await termServices.getTermLookupList({ termYear: searchTerm })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [termServices]
    )

    const termLookupById = useCallback(
        async (key) => {
            try {
                return await termServices.getTermLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },

        [termServices]
    )

    const termStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await termLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return termLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const classLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (!filterFields.termSelectedData) return []
                return await classesService.getClassLookupList({
                    termId: filterFields.termSelectedData,
                    className: searchTerm,
                })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [classesService, filterFields.termSelectedData]
    )

    const classLookupById = useCallback(
        async (key) => {
            try {
                return await classesService.getClassLookupById(filterFields.termSelectedData, key)
            } catch (error) {
                handleError(error)
            }
        },

        [classesService]
    )

    const classStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await classLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return classLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        [filterFields.termSelectedData]
    )

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({
                    firstName: searchTerm,
                })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },

        [customerServices]
    )

    const customerStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await customerLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return customerLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )
    const onFormSubmit = () => {
        mutation.mutate({
            termId: utilServices.isNullOrUndefined(filterFields.termSelectedData) ? '' : filterFields.termSelectedData,
            classId: utilServices.isNullOrUndefined(filterFields.classSelectedData)
                ? ''
                : filterFields.classSelectedData,
            sort: '',
            ...filter,
            hasBalance: showBalance,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit()
        setSubmitting(false)
    }
    const handleClear = () => {
        setFilterFields({ ...initialFilterFields })
        setFilter({ customerId: null })
        setShowBalance(false)
    }

    const onCustomerSelectionChanged = (value) => {
        setFilter({ ...filter, customerId: value?.id })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Term</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={termStore}
                                        itemTemplate={(data) => data.termYear + ' / ' + data.termNumber}
                                        displayExpr={(data) => (data ? data.termYear + ' / ' + data.termNumber : '')}
                                        value={filterFields.termSelectedData}
                                        searchEnabled={true}
                                        onValueChange={onTermValueChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Class</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={classStore}
                                        displayExpr={'className'}
                                        value={filterFields.classSelectedData}
                                        searchEnabled={true}
                                        onValueChange={onClassValueChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={customerStore}
                                        itemTemplate={(data) => data.firstName + '  ' + data.lastName}
                                        displayExpr={(data) => (data ? data.firstName + '  ' + data.lastName : '')}
                                        value={filter.customerId}
                                        searchEnabled={true}
                                        onValueChange={onCustomerSelectionChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 3.5 }}>
                                <Div sx={{ mt: 0.5 }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            type='checkbox'
                                            checked={showBalance}
                                            onChange={(e) => setShowBalance(!showBalance)}
                                            label='Has Balance'
                                        />
                                    </FormGroup>
                                </Div>
                            </Div>
                            <Div sx={{ mt: 4.5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                    <Div />
                </Form>
            )}
        </Formik>
    )
}

export default PaymentListReportFilter
