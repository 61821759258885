import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import TextBox from 'devextreme-react/text-box'
import notify from 'devextreme/ui/notify'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Div from '@jumbo/shared/Div'
import LoadingButton from '@mui/lab/LoadingButton'
import { SelectBox } from 'devextreme-react/select-box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { getTermLookupList } from 'app/redux/store/features/terms'
import { getClassLookupList, duplicateClass } from 'app/redux/store/features/classes'

const ClassDuplicateForm = ({ classId, termId, termYear, termNumber, onRefresh, handleError }) => {
    const { hideDialog } = useJumboDialog()
    const dispatch = useDispatch()
    const [termSelection, setTermSelection] = useState(termId)
    const [classSelection, setClassSelection] = useState('')
    const { termLookups, loading: termLoading, error: termError } = useSelector((state) => state.terms)
    const { classLookups, loading: classLoading, error: classError } = useSelector((state) => state.classes)

    useEffect(() => {
        const error = termError || classError
        if (error) handleError()
    }, [termLoading, classLoading])

    useEffect(() => {
        dispatch(getTermLookupList({ search: '', termYear: termYear, termNumber: termNumber }))
        dispatch(getClassLookupList({ termId: termId }))
    }, [])

    const termLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: termLookups,
            totalCount: termLookups.length,
        }),
        reshapeOnPush: true,
    })

    const classLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: classLookups,
            totalCount: classLookups.length,
        }),
        reshapeOnPush: true,
    })

    const hideDialogAndRefreshList = () => {
        hideDialog()
        notify({ message: 'Class duplicated successfully', width: 300, position: 'top right' }, 'success', 1500)
        onRefresh() // Calling the refresh function from the parent component
    }

    const duplicateClassMutation = useMutation((data) => {
        dispatch(duplicateClass(data))
        hideDialogAndRefreshList()
    })

    const onFormSubmit = () => {
        duplicateClassMutation.mutate({
            classId: classId,
            termId: termSelection.id ? termSelection.id : termSelection,
            className: classSelection,
        })
    }

    return (
        <Div>
            <Div sx={{ borderBottom: 1, borderColor: 'success.main' }}>
                <Div sx={{ m: 3 }}>
                    <Typography>Source Class</Typography>
                    <SelectBox
                        height={40}
                        dataSource={classLookupsStore}
                        displayExpr={'className'}
                        value={classId}
                        readOnly={true}
                    />
                </Div>
            </Div>
            <Div sx={{ m: 3 }}>
                <Typography>Term Year</Typography>
                <SelectBox
                    height={40}
                    dataSource={termLookupsStore}
                    itemTemplate={(data) => data.termYear + ' / ' + data.termNumber}
                    displayExpr={(data) => (data ? data.termYear + ' / ' + data.termNumber : '')}
                    value={termSelection}
                    searchEnabled={true}
                    onValueChange={setTermSelection}
                    placeholder='All'
                    showClearButton={true}
                />
                <Div sx={{ mt: 1.5, mb: 5 }}>
                    <Typography>New Class Name</Typography>
                    <TextBox
                        value={classSelection}
                        height={40}
                        showClearButton={true}
                        placeholder='Enter class name'
                        onValueChange={setClassSelection}
                    />
                </Div>
            </Div>
            <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                <LoadingButton
                    variant='contained'
                    color='success'
                    onClick={onFormSubmit}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    Save
                </LoadingButton>
                <LoadingButton variant='outlined' color='success' onClick={hideDialog} size='large'>
                    {' '}
                    Cancel
                </LoadingButton>
            </Stack>
        </Div>
    )
}

export default ClassDuplicateForm
