import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    enrolmentListReport: { data: [], totalCount: 0 },
    paymentListReport: { data: [], totalCount: 0 },
    loading: false,
    error: null,
}

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getEnrolmentList: (state, action) => {
            state.enrolmentListReport = action.payload
            state.loading = false
        },
        getPaymenttList: (state, action) => {
            state.paymentListReport = action.payload
            state.loading = false
        },
    },
})

export const { apiRequested, apiRequestedFailed, getEnrolmentList, getPaymenttList } = reportSlice.actions
export default reportSlice.reducer

export const getEnrolmentListReport = (filter) => {
    let url = `api/Report/enrolment/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getEnrolmentList.type,
        onError: apiRequestedFailed.type,
    })
}

export const getPaymenttListReport = (filter) => {
    let url = `api/Report/payment/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getPaymenttList.type,
        onError: apiRequestedFailed.type,
    })
}
