import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    locations: { data: [], totalCount: 0 },
    locationLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const locationSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
            state.refreshing = false
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getLocations: (state, action) => {
            state.locations = action.payload
            state.loading = false
            state.refreshing = false
        },
        getLocationLookup: (state, action) => {
            state.locationLookups = action.payload
            state.loading = false
        },
        getExistingLocationLookupById: (state, action) => {
            const index = state.locationLookups.findIndex((location) => location.id === action.payload.id)
            if (index >= 0) state.locationLookups[index] = action.payload
            else {
                state.locationLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingLocationById: (state, action) => {
            const index = state.locations.data.findIndex((location) => location.id === action.payload.id)
            if (index >= 0) state.locations[index] = action.payload
            else {
                state.locations.data.push(action.payload)
                state.locations.totalCount++
            }
            state.loading = false
        },
        addNewLocation: (state, action) => {
            state.locations.data.push(action.payload)
            state.locations.totalCount++
            state.loading = false
        },
        updateExistingLocation: (state, action) => {
            const index = state.locations.data.findIndex((location) => location.id === action.payload.id)
            if (index >= 0) state.locations[index] = action.payload
            state.loading = false
        },
        removeLocation: (state, action) => {
            const index = state.locations.data.findIndex((location) => location.id === action.payload.id)
            if (index >= 0) {
                state.locations.data.splice(index, 1)
                state.locations.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getLocations,
    getLocationLookup,
    getExistingLocationLookupById,
    getExistingLocationById,
    addNewLocation,
    updateExistingLocation,
    removeLocation,
} = locationSlice.actions
export default locationSlice.reducer

export const getLocationList = (filter, refresh = false) => {
    let url = `/api/Setting/location/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getLocations.type,
        onError: apiRequestedFailed.type,
    })
}

export const getLocationLookupList = (filter) => {
    let url = filter?.locationName
        ? `/api/Setting/location/lookup/list?search=${filter.locationName}`
        : `/api/Setting/location/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getLocationLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getLocationLookupById = (filter) => {
    let url = `/api/Setting/location/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingLocationLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getLocationById = (filter) => {
    let url = `/api/Setting/location/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingLocationById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addLocation = (location) => {
    let url = `/api/Setting/location/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: location,
        onStart: apiRequested.type,
        onSuccess: addNewLocation.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateLocation = (location) => {
    let url = `/api/Setting/location/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: location,
        onStart: apiRequested.type,
        onSuccess: updateExistingLocation.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteLocation = (location) => {
    let url = '/api/Setting/location/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: location,
        onStart: apiRequested.type,
        onSuccess: removeLocation.type,
        onError: apiRequestedFailed.type,
    })
}
