export const defaultEnrolment = {
    customerId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    familyId: null,
    memberId: null,
    termId: null,
    classId: null,
    sectionId: null,
}
