import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { Grid } from '@mui/material'
import { Button as ButtonMui } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getSectionList, clearSections } from 'app/redux/store/features/sections'
import SectionGridData from './components/SectionGridData'

const SectionsGrid = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const [filter, setFilter] = useState()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 10
    const { classConfig, loading, error } = useSelector((state) => state.classes)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    useEffect(() => {
        setApiCallInProgress(true)
        if (classConfig.classId) {
            setFilter({
                classId: classConfig.classId,
            })
            dispatch(getSectionList({ classId: classConfig.classId, skip: 0, take: pageSize }))
        } else dispatch(clearSections())
    }, [classConfig.classId, dispatch])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getSectionList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getSectionList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getSectionList({ ...filter, skip: 0, take: pageSize }))
        setCurrentPage(0)
    }

    const onAddSection = () => {
        if (classConfig.classId)
            return navigate(`/courses/setup/classes/section/add/${classConfig.classId}`, {
                state: { id: classConfig.classId },
            })
        showDialog({
            title: 'Please select a class and try again.',
            content: (
                <Div sx={{ textAlign: 'center', m: 1 }}>
                    <ButtonMui size={'small'} type='submit' onClick={hideDialog} variant='contained' color='success'>
                        Ok
                    </ButtonMui>
                </Div>
            ),
        })
    }

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.3,
                            mr: 1,
                            ml: -2,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Section List
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'success.main',
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        fontSize={'large'}
                        variant={'contained'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={onAddSection}
                    />
                </Stack>
            }>
            <Grid>
                <SectionGridData
                    onRefresh={onRefresh}
                    onPageChange={onPageChange}
                    onColumnSort={onColumnSort}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    apiCallInProgress={apiCallInProgress}
                    setApiCallInProgress={setApiCallInProgress}
                    handleError={handleError}
                />
            </Grid>
        </JumboCardQuick>
    )
}
export default SectionsGrid
