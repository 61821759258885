export const CanLogin = 1
export const CanHaveWriteAccess = 2

export const CanViewSecurity = 15
export const CanViewCourses = 25
export const CanViewRegistrations = 35
export const CanViewMessages = 45
export const CanViewSettings = 55
export const CanViewReports = 65
export const CanViewProfileSettings = 70
