import React from 'react'
import Div from '@jumbo/shared/Div'
import { Typography } from '@mui/material'
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList'
import useReportApp from '../../hooks/useReportApp'
import ReportViewItem from '../ReportViewItem'
import reportServices from '../../../../services/pages/reports/report-services'

const PaymentReportList = () => {
    const { paymentReportListRef } = useReportApp()

    const renderReportView = React.useCallback((reportItem) => {
        return <ReportViewItem reportViewItem={reportItem} />
    }, [])
    return (
        <React.Fragment>
            <Div
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: (theme) => theme.spacing(1.5, 2),
                    bgcolor: (theme) => theme.palette.action.hover,
                }}>
                <Typography
                    sx={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
                    variant='h6'
                    color='text.secondary'
                    mb={0}>
                    <small>Payment Reports</small>
                </Typography>
            </Div>
            <JumboRqList
                ref={paymentReportListRef}
                service={reportServices.getPaymentReportList}
                primaryKey={'id'}
                queryOptions={{
                    queryKey: 'payment-report-list',
                    dataKey: 'reports',
                }}
                renderItem={renderReportView}
                componentElement={'div'}
            />
        </React.Fragment>
    )
}

export default PaymentReportList
