export const defaultNotifyBarData = {
    heading: '',
    subText: '',
    sideText: '',
    orderBy: '1',
    iconType: 0,
    dateStart: '',
    dateEnd: '',
    navigationLink: '',
}
