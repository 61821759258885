import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import DataSource from 'devextreme/data/data_source'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { PatternRule } from 'devextreme-react/data-grid'
import GenderTypeService from 'app/pages/registrations/customers/components/GenderTypeService'
import RelationshipTypeService from './RelationshipTypeService'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import { useSelector } from 'react-redux'
import familyAndMemberService from 'app/services/pages/reports/registrations/familyAndMemberService/familyAndMemberService'
import { useSnackbar } from 'notistack'

const UpdateFamilyMemberForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { enqueueSnackbar } = useSnackbar()
    const [showPrimaryContact, setShowPrimaryContact] = useState(props.memberData.isPrimary)
    const { loading } = useSelector((state) => state.members)

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const familyLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await familyAndMemberService.getFamilyLookupList({ familyName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService]
    )

    const familyLookupById = useCallback(
        async (key) => {
            try {
                return await familyAndMemberService.getFamilyLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService]
    )

    const familyLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await familyLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return familyLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const familyEditorOptions = {
        dataSource: familyLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
        displayExpr: function (data) {
            return data ? data.familyName.toFamilyContactName(data.contactName) : ''
        },
    }

    const genderEditorOptions = {
        dataSource: GenderTypeService.getGenderType(),
        displayExpr: 'genderType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }
    const phonePattern = /^\+[1-9]\d{10,14}$/

    const onSelectionChanged = (e) => {
        // Update the state based on the value of e.value
        setShowPrimaryContact(e.value)
    }

    const checkBoxEditorOption = {
        onValueChanged: onSelectionChanged,
    }

    const primaryContactLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const primaryContactLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const primaryContactLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await primaryContactLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return primaryContactLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const primaryContactEditorOptions = {
        dataSource: primaryContactLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate(`/registrations/families`),
    }

    const validationRules = {
        familyName: [{ type: 'required', message: 'Family name is required.' }],
        firstName: [{ type: 'required', message: 'First name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
        genderType: [{ type: 'required', message: 'Gender type is required.' }],
    }

    const relationshipEditorOptions = {
        dataSource: RelationshipTypeService.getRelationshipType(),
        displayExpr: 'relationshipType',
        valueExpr: 'id',
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Member
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateMember)}>
                    <Form
                        mode='form'
                        formData={props.memberData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Update Member' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='isPrimary'
                                    editorType='dxCheckBox'
                                    editorOptions={{
                                        ...checkBoxEditorOption,
                                        value: showPrimaryContact, // Set the value of the checkbox explicitly
                                    }}
                                />
                                {showPrimaryContact && (
                                    <SimpleItem
                                        editorType='dxSelectBox'
                                        dataField='customerId'
                                        editorOptions={primaryContactEditorOptions}>
                                        <Label text='Primary Contact' />
                                    </SimpleItem>
                                )}
                                <SimpleItem
                                    dataField='familyId'
                                    editorType='dxSelectBox'
                                    editorOptions={familyEditorOptions}
                                    validationRules={validationRules.familyName}>
                                    <Label text='Family Name' />
                                </SimpleItem>
                                <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                <SimpleItem dataField='lastName' />
                                <SimpleItem dataField='email' validationRules={validationRules.email} />
                                <SimpleItem dataField='phoneNumber' editorOptions={phoneEditorOptions}>
                                    <Label text='Phone Number' />
                                    <PatternRule
                                        message='phone number must start with + and needs to include country code'
                                        pattern={phonePattern}
                                    />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='genderType'
                                    editorType='dxSelectBox'
                                    editorOptions={genderEditorOptions}
                                    validationRules={validationRules.genderType}
                                />
                                <SimpleItem
                                    editorType='dxSelectBox'
                                    dataField='relationshipType'
                                    validationRules={validationRules.relationshipType}
                                    editorOptions={relationshipEditorOptions}>
                                    <Label text='Relationship' />
                                </SimpleItem>
                                <SimpleItem dataField='birthDate' editorType='dxDateBox' />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateFamilyMemberForm
