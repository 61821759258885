import React, { useEffect } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import HeaderCard from './components/headerbar/HeaderCard'
import BannerLeft from './components/bannerbar/BannerLeft'
import BannerRight from './components/bannerbar/BannerRight'
import LeftInfoPanel from './components/infoSection/leftInfoPanel/LeftInfoPanel'
import RightInfoPanel from './components/infoSection/rightInfoPanel/RightInfoPanel'
import { getTermLookupList } from '../../redux/store/features/terms'
import { useDispatch, useSelector } from 'react-redux'
import { getTermDashboard } from '../../redux/store/features/dashboard'
import utilServices from '../../services/util-services'

const Dashboard = () => {
    const dispatch = useDispatch()
    const { termSummary } = useSelector((state) => state.dashboard)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(termSummary?.id)) dispatch(getTermDashboard({}))
    }, [])

    return (
        <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} lg={12}>
                <HeaderCard />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <BannerLeft />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <BannerRight />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <LeftInfoPanel />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <RightInfoPanel />
            </Grid>
        </Grid>
    )
}

export default Dashboard
