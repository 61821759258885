import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { selectItemById, getBannerById, updateBanner } from 'app/redux/store/features/banners'
import UpdateBannerBarForm from './UpdateBannerBarForm'

const UpdateBannerBar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { state } = useLocation()
    const { id } = state
    const { banners, loading, error } = useSelector((state) => state.banners)
    const banner = selectItemById(banners, id)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(banners.id)) dispatch(getBannerById({ id: id }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Banner Bar updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/configuration/banner-bar')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateBannerBar = async () => {
        setApiCallInProgress(true)
        dispatch(updateBanner({ ...banner }))
    }

    return (
        <React.Fragment>
            <UpdateBannerBarForm bannerBarData={banner} updateBannerBar={onUpdateBannerBar} />
        </React.Fragment>
    )
}

export default UpdateBannerBar
