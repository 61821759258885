import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    placements: { data: [], totalCount: 0 },
    placementConfig: { reloadAfterUpdate: false },
    loading: false,
    error: null,
    refreshing: false,
}

const placementSlice = createSlice({
    name: 'placements',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getPlacementConfig: (state, action) => {
            state.placementConfig = action.payload
        },
        updatePlacementConfig: (state, action) => {
            state.placementConfig = action.payload
        },
        getPlacements: (state, action) => {
            state.placements = action.payload
            state.loading = false
            state.refreshing = false
        },
        updateExistingPlacements: (state, action) => {
            if (action.payload.data?.length > 0) {
                action.payload.data.foreach((item) => {
                    const index = state.placements.data.findIndex((placement) => placement.id === item.id)
                    if (index >= 0) state.placements[index] = item
                })
            }
            state.loading = false
            state.placementConfig = { ...state.placementConfig, reloadAfterUpdate: true }
        },
        clearPlacement: (state, action) => {
            state.placements = { data: [], totalCount: 0 }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getPlacements,
    updateExistingPlacements,
    clearPlacement,
    getPlacementConfig,
    updatePlacementConfig,
} = placementSlice.actions
export default placementSlice.reducer

export const getPlacementList = (filter, refresh) => {
    let url = `/api/Placement/enroled/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getPlacements.type,
        onError: apiRequestedFailed.type,
    })
}

export const updatePlacement = (placements) => {
    let url = `/api/Placement/enroled/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: placements,
        onStart: apiRequested.type,
        onSuccess: updateExistingPlacements.type,
        onError: apiRequestedFailed.type,
    })
}
