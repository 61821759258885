import jwtAuthAxios from 'app/services/auth/jwtAuth'
const classesService = {}

// For current term term
classesService.getClassLookupList = async (filter) => {
    let url = filter?.className
        ? `/api/Class/lookup/list/term/${filter.termId}?search=${filter.className}`
        : `/api/Class/lookup/list/term/${filter.termId}`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })

    return data
}

// For previous term
classesService.getPrvTermClassLookupList = async (filter) => {
    let url = filter?.className
        ? `/api/Class/lookup/list/term/${filter.termId}/prv?search=${filter.className}`
        : `/api/Class/lookup/list/term/${filter.termId}/prv`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })

    return data
}

classesService.getClassLookupById = async (key) => {
    let url = `/api/Class/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default classesService
