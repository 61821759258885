let IconTypeService = {}

IconTypeService.setIconTypeList = [
    { id: 0, iconType: 'Info' },
    { id: 1, iconType: 'Exclamation' },
    { id: 2, iconType: 'Warning' },
    { id: 3, iconType: 'Male' },
    { id: 4, iconType: 'Female' },
    { id: 5, iconType: 'TimeClock' },
]

IconTypeService.getIconTypeInfo = () => {
    return IconTypeService.setIconTypeList
}

IconTypeService.getIconTypeInfoData = (row) => {
    const iconType = IconTypeService.setIconTypeList.find((item) => item.id === row.iconType)
    return iconType ? iconType.iconType : ''
}

export default IconTypeService
