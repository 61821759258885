export const defaultUserGrid = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    comments: '',
    birthDate: null,
    userType: null,
    customerId: null,
}
