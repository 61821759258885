import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AddIcon from '@mui/icons-material/Add'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getClasses, getClassList } from 'app/redux/store/features/classes'
import ClassGridFilter from './components/ClassGridFilter'
import ClassGridData from './components/ClassGridData'

const ClassGrid = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 12
    const { termConfig, loading, error } = useSelector((state) => state.terms)

    const { state } = useLocation()
    const [filter, setFilter] = useState(() =>
        state?.navigateFrom === 'cancel-add-class' ||
        state?.navigateFrom === 'cancel-update-class' ||
        state?.navigateFrom === 'add-class' ||
        state?.navigateFrom === 'update-class'
            ? { termYear: termConfig.termYear, termNumber: termConfig.termNumber }
            : { termYear: null, termNumber: null }
    )

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    useEffect(() => {
        setApiCallInProgress(true)
        if (termConfig.termYear) {
            // Only reload if filter is different
            //if (filter?.termYear !== termConfig.termYear && filter?.termNumber !== termConfig?.termNumber) {
            setFilter({ termYear: termConfig.termYear, termNumber: termConfig.termNumber })
            dispatch(
                getClassList({
                    termYear: termConfig.termYear,
                    termNumber: termConfig.termNumber,
                    skip: 0,
                    take: pageSize,
                })
            )
            //}
        } else {
            dispatch(getClasses({ totalCount: 0, data: [] }))
        }
    }, [termConfig.termYear, termConfig.termNumber])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function userReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [classGrid, setClassGrid] = React.useReducer(
        userReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            currentfilter = { ...currentfilter, termYear: termConfig.termYear, termNumber: termConfig.termNumber }
            dispatch(getClassList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getClassList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getClassList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getClassList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setClassGrid({ type: 'set-grid-filter', payload: value })
        },
        [setClassGrid]
    )

    const onAddClass = (e) => {
        navigate(`/courses/setup/classes/add/${termConfig.termId}`, { state: { id: termConfig.termId } })
    }

    const setFilterIconColor = React.useCallback(
        (value) => {
            setClassGrid({ type: 'set-filter-icon-color', payload: value })
        },
        [setClassGrid]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.3,
                            mr: 0.5,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Class List
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'success.main',
                pl: 1,
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        fontSize={'large'}
                        variant={'contained'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={onAddClass}
                    />
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            mt: -1.8,
                            color:
                                classGrid.filterIconColor && !classGrid.gridFilterVisible
                                    ? 'secondary.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!classGrid.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <ClassGridFilter
                    mutation={applyFilter}
                    display={classGrid.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Grid>
                <ClassGridData
                    onRefresh={onRefresh}
                    onPageChange={onPageChange}
                    onColumnSort={onColumnSort}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    apiCallInProgress={apiCallInProgress}
                    setApiCallInProgress={setApiCallInProgress}
                    handleError={handleError}
                />
            </Grid>
        </JumboCardQuick>
    )
}
export default ClassGrid
