import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

const UpdateClassTypeForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.classTypes)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/definitions/class-types'),
    }

    const validationRules = {
        classTypeName: [{ type: 'required', message: 'Class Type Name is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Class Type
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateClassType)}>
                    <Form
                        mode='form'
                        formData={props.classTypeData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Update Class Type' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='classTypeName' validationRules={validationRules.classTypeName}>
                                    <Label text='Class Type Name' />
                                </SimpleItem>
                                <SimpleItem dataField='comments' editorType='dxTextArea' />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateClassTypeForm
