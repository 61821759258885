import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import UserTypeList from './components/userSidebar/UserTypeList'
import PermissionsGrid from './components/permissions/PermissionsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateUserTypeConfig } from 'app/redux/store/features/users'
import Div from '@jumbo/shared/Div'
import { Grid, useMediaQuery } from '@mui/material'
import { useJumboTheme } from '@jumbo/hooks'

const Permissions = () => {
    const { userTypeName } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { userTypes, loading, error } = useSelector((state) => state.users)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (userTypeName) {
            userTypes.map((item) => {
                if (item.typeName.toLowerCase() === userTypeName) {
                    dispatch(updateUserTypeConfig({ id: item.id, readOnly: item.readOnly }))
                }
            })
        } else {
            dispatch(updateUserTypeConfig({ id: userTypes[0]?.id, readOnly: userTypes[0]?.readOnly }))
        }
    }, [userTypes])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <Div>
            {min ? (
                <Grid container direction='column' spacing={2}>
                    <Grid item sx={{ width: '100%' }}>
                        <UserTypeList handleError={handleError} />
                    </Grid>
                    <Grid item sx={{ width: '100%' }}>
                        <PermissionsGrid handleError={handleError} />
                    </Grid>
                </Grid>
            ) : (
                // Desktop layout: Sidebar with FamilyGrid and MemberGrid in content area
                <JumboContentLayout sidebar={<UserTypeList handleError={handleError} />}>
                    <PermissionsGrid handleError={handleError} />
                </JumboContentLayout>
            )}
        </Div>
    )
}

export default Permissions
