import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery, Box } from '@mui/material'
import BannerBar from './bannerbar/BannerBar'
import NotifyBar from './notifybar/NotifyBar'
import InfoBar from './infobar/InfoBar'
import Config from './Config/components/Config'
import Div from '@jumbo/shared/Div'

function Configuration() {
    const navigate = useNavigate()
    const { selectedTab } = useParams()
    const [tabTitle, setTabTitle] = React.useState(selectedTab ? selectedTab : 'banner-bar')
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const onTabSelectionChanged = (event, newValue) => {
        setTabTitle(newValue)
        navigate(`/settings/configuration/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Configuration
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <Tabs
                    value={tabTitle}
                    onChange={onTabSelectionChanged}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                    sx={{
                        bgcolor: 'background.default',
                        mt: 2,
                        mb: 0,
                        ml: 3.1,
                        mr: 3.1,
                        '.MuiTabs-scrollButtons': {
                            // Styling for scroll buttons
                            '&.Mui-disabled': {
                                opacity: 0.3, // Ensure disabled buttons are visible but faded
                            },
                        },
                    }}>
                    <Tab
                        label='Banner Bar'
                        value='banner-bar'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Info Bar'
                        value='info-bar'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Notify Bar'
                        value='notify-bar'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Config'
                        value='config'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                </Tabs>
                <Div>
                    {tabTitle === 'banner-bar' && (
                        <Box p={3}>
                            <BannerBar />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'info-bar' && (
                        <Box p={3}>
                            <InfoBar />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'notify-bar' && (
                        <Box p={3}>
                            <NotifyBar />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'config' && (
                        <Box p={3}>
                            <Config />
                        </Box>
                    )}
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default Configuration
