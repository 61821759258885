import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    enrolments: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const enrolmentSlice = createSlice({
    name: 'enrolments',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },

        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getEnrolments: (state, action) => {
            state.enrolments = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingEnrolmentById: (state, action) => {
            const index = state.enrolments.data.findIndex((enrolment) => enrolment.id === action.payload.id)
            if (index >= 0) state.enrolments[index] = action.payload
            else {
                state.enrolments.data.push(action.payload)
                state.enrolments.totalCount++
            }
            state.loading = false
        },
        addNewEnrolment: (state, action) => {
            state.enrolments.data.push(action.payload)
            state.enrolments.totalCount++
            state.loading = false
        },
        updateExistingEnrolment: (state, action) => {
            const index = state.enrolments.data.findIndex((enrolment) => enrolment.id === action.payload.id)
            if (index >= 0) state.enrolments[index] = action.payload
            state.loading = false
        },
        removeEnrolment: (state, action) => {
            const index = state.enrolments.data.findIndex((enrolment) => enrolment.id === action.payload.id)
            if (index >= 0) {
                state.enrolments.data.splice(index, 1)
                state.enrolments.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getEnrolments,
    getExistingEnrolmentById,
    addNewEnrolment,
    updateExistingEnrolment,
    removeEnrolment,
} = enrolmentSlice.actions
export default enrolmentSlice.reducer

export const getEnrolmentList = (filter, refresh = false) => {
    let url = `/api/Enrolment/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getEnrolments.type,
        onError: apiRequestedFailed.type,
    })
}

export const getEnrolmentById = (filter) => {
    let url = `/api/Enrolment/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingEnrolmentById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addEnrolment = (enrolment) => {
    let url = `/api/Enrolment/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: enrolment,
        onStart: apiRequested.type,
        onSuccess: addNewEnrolment.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateEnrolment = (enrolment) => {
    let url = `/api/Enrolment/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: enrolment,
        onStart: apiRequested.type,
        onSuccess: updateExistingEnrolment.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteEnrolment = (enrolment) => {
    let url = `/api/Enrolment/delete`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: enrolment,
        onStart: apiRequested.type,
        onSuccess: removeEnrolment.type,
        onError: apiRequestedFailed.type,
    })
}
