import jwtAuthAxios from 'app/services/auth/jwtAuth'
const locationServices = {}

locationServices.getLocationLookupList = async (filter) => {
    let url = filter?.locationName
        ? `/api/Setting/location/lookup/list?search=${filter.locationName}`
        : `/api/Setting/location/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw new Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw new Error(error.message)
    })
    return data
}

locationServices.getLocationById = async (key) => {
    let url = `/api/Setting/location/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw new Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw new Error(error.message)
    })
    return data
}

export default locationServices
