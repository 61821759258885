import jwtAuthAxios from 'app/services/auth/jwtAuth'
const familyAndMemberService = {}

familyAndMemberService.getFamilyLookupList = async (filter) => {
    let url = filter?.familyName ? `/api/Family/lookup/list?search=${filter.familyName}` : `/api/Family/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

familyAndMemberService.getFamilyLookupById = async (key) => {
    let url = `/api/Family/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

familyAndMemberService.getMemberLookupList = async (filter) => {
    console.log('filter  in Member = ', filter)
    let url = filter?.memberName
        ? `/api/Family/${filter.familyId}/member/lookup/list?search=${filter.memberName}`
        : `/api/Family/${filter.familyId}/member/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })

    return data
}

familyAndMemberService.getMemberLookupById = async (familyId, key) => {
    let url = `/api/Family/${familyId}/member/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}
export default familyAndMemberService
