import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { updateIndicator } from 'app/redux/store/features/configs'
import { getFamilyById, selectItemById, updateFamily } from 'app/redux/store/features/families'
import UpdateFamilyForm from './UpdateFamilyForm'

const UpdateFamily = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { state } = useLocation()
    const { familieId } = state
    const { families, loading, error } = useSelector((state) => state.families)
    const family = selectItemById(families, familieId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(family.id)) dispatch(getFamilyById({ id: familieId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Family updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            dispatch(updateIndicator({ inProgress: false }))
            navigate('/registrations/families')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateFamily = async () => {
        setApiCallInProgress(true)
        dispatch(updateIndicator({ inProgress: true }))
        dispatch(updateFamily({ ...family }))
    }

    return (
        <React.Fragment>
            <UpdateFamilyForm familyData={family} updateFamily={onUpdateFamily} handleError={handleError} />
        </React.Fragment>
    )
}

export default UpdateFamily
