import React from 'react'
import { Grid, Typography } from '@mui/material'
import CardIconText from '@jumbo/shared/CardIconText'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import MosqueIcon from '@mui/icons-material/Mosque'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill'
import ReactSpeedometer from 'react-d3-speedometer'

const HeaderCardList = ({ item }) => {
    const getCardStyle = (icon) => {
        switch (icon) {
            case 'quran':
                return { color: '#00A073', iconComponent: <MenuBookIcon fontSize='large' /> }
            case 'dua':
                return { color: '#DF295E', iconComponent: <LocalLibraryIcon fontSize='large' /> }
            case 'event':
                return { color: '#008598', iconComponent: <OutdoorGrillIcon fontSize='large' /> }
            default:
                return { color: '#BB6900', iconComponent: <MosqueIcon fontSize='large' /> }
        }
    }

    return (
        <CardIconText
            icon={getCardStyle(item.icon).iconComponent}
            color={getCardStyle(item.icon).color}
            sx={{
                ml: 0,
                mr: 0,
            }}
            title={
                <Grid sx={{ mt: '12.5%', ml: -3.2 }}>
                    <Typography variant={'h4'} color={getCardStyle(item.icon).color} fontFamily='Lobster, cursive'>
                        {item.className}
                    </Typography>
                </Grid>
            }
            subTitle={
                <React.Fragment>
                    <Typography
                        variant={'h6'}
                        sx={{
                            pt: 0.5,
                            ml: -3.5,
                            fontSize: '13px',
                            textAlign: 'center',
                            maxWidth: '100px', // Set your desired maximum width
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                        }}>
                        {'  '} {item.subTitle}
                    </Typography>
                    <Typography variant={'h5'} sx={{ ml: '59%', mt: -2.5, fontSize: '13px' }}>
                        ${item.classPrice}
                    </Typography>
                    <Grid sx={{ ml: '60%', mt: -9.5 }}>
                        <ReactSpeedometer
                            strokeRadius={0.5}
                            value={item.registered}
                            maxValue={item.capacity}
                            maxSegmentLabels={0}
                            segments={2}
                            ringWidth={7}
                            needleColor={'#555'}
                            needleHeightRatio={0.5}
                            needleTransitionDuration={item.capacity}
                            needleTransition='easeElastic'
                            segmentColors={[getCardStyle(item.icon).color, '#AAA']}
                            currentValueText={''}
                            valueTextFontSize={'18px'}
                            valueTextFontWeight={'normal'}
                            textColor={'#555'}
                            width={100}
                            height={60}
                            segmentStops={[0.3, 0.7]}
                        />
                        <Typography variant={'h5'} sx={{ ml: 7.7, mt: -1.9 }}>
                            {item.capacity}
                        </Typography>
                        <Typography variant={'h5'} color={getCardStyle(item.icon).color} sx={{ ml: 5, mt: -2.3 }}>
                            {item.registered}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={getCardStyle(item.icon).color}
                            sx={{ ml: 1, mt: -1.6, mb: '20%' }}>
                            Enrolments
                        </Typography>
                    </Grid>
                </React.Fragment>
            }
            disableHoverEffect={true}
            hideArrow={true}
            variant={'outlined'}
        />
    )
}

export default HeaderCardList
