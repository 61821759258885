import React from 'react'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import { Card, CardContent, ListItem, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { LinearProgress } from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useSelector } from 'react-redux'
import styled from '@mui/material/styles/styled'
import Span from '@jumbo/shared/Span'
import { formattedDate } from 'app/services/utils/components/FormatDateTime/FormatDateTime'

const TermSummary = () => {
    const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 6,
        borderRadius: 5,
        flex: 1,
    }))

    const { termSummary } = useSelector((state) => state.dashboard)
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={
                            <Div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography
                                    variant={'h3'}
                                    fontFamily='Lobster, cursive'
                                    color={'text.primary'}
                                    sx={{ m: 0 }}>
                                    <Span sx={{ color: 'warning.dark', mr: 1 }}>{termSummary.termName}</Span>
                                </Typography>
                                <Typography variant={'h3'} color={'text.primary'}>
                                    Enrolments:
                                    <Span sx={{ color: 'warning.dark', ml: 1 }}>{termSummary.totalEnrolments}</Span>
                                </Typography>
                            </Div>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Stack direction={'row'} spacing={2}>
                            <Grid item xs={12} sm={7}>
                                <Typography component={'div'} variant={'body1'} mb={1}>
                                    <Typography component={'span'} variant={'h1'} color={'warning.dark'}>
                                        Year {termSummary.termYear}/{termSummary.termNumber}
                                    </Typography>
                                </Typography>
                                <List>
                                    <ListItem disablePadding sx={{ mb: 1 }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: 14, mr: 1 }} />
                                        Total Classes: {termSummary.totalClasses}
                                    </ListItem>
                                    <ListItem disablePadding sx={{ mb: 1 }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: 14, mr: 1 }} />
                                        Start Date:
                                        <Span sx={{ paddingLeft: '1%' }}>{formattedDate(termSummary.dateStart)}</Span>
                                    </ListItem>
                                    <ListItem disablePadding sx={{ mb: 1 }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: 14, mr: 1 }} />
                                        End Date:
                                        <Span sx={{ paddingLeft: '1%' }}>{formattedDate(termSummary.dateEnd)}</Span>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Div sx={{ textAlign: 'center' }}>
                                    {termSummary.gender.map((item) => (
                                        <React.Fragment key={item.genderType}>
                                            <Typography variant={'body1'} color={'text.secondary'}>
                                                {item.genderType}
                                            </Typography>
                                            <Div
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    mb: 2,
                                                }}>
                                                <StyledLinearProgress
                                                    variant={'determinate'}
                                                    color={
                                                        item.genderType === 'Male'
                                                            ? 'secondary'
                                                            : item.genderType === 'Female'
                                                            ? 'info'
                                                            : 'warning'
                                                    }
                                                    value={item.count}
                                                />
                                                <Typography variant={'body1'} color={'text.secondary'} ml={1}>
                                                    {item.count + ' %'}
                                                </Typography>
                                            </Div>
                                        </React.Fragment>
                                    ))}
                                </Div>
                            </Grid>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default TermSummary
