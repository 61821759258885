import React from 'react'

import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { Card, useMediaQuery } from '@mui/material'
import useReportApp from '../hooks/useReportApp'
import ReportAppSidebar from '../components/ReportAppSidebar'
import ReportAppContent from '../components/ReportAppContent'

const ReportAppLayout = () => {
    const { theme } = useJumboTheme()
    const { activeReport } = useReportApp()
    const md = useMediaQuery(theme.breakpoints.down('md'))

    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx:
                    md && activeReport
                        ? { display: 'none' }
                        : {
                              width: 280,
                              marginRight: 0,
                              borderRight: 1,
                              borderRightColor: (theme) => theme.palette.divider,
                              [theme.breakpoints.down('md')]: {
                                  width: 'auto',
                                  border: 'none',
                              },
                          },
            },
            wrapper: {
                component: Card,
                sx: {
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                    },
                },
            },
        }),
        [theme, md, activeReport]
    )

    return (
        <JumboContentLayout sx={{ marginRight: '440px' }} sidebar={<ReportAppSidebar />} layoutOptions={layoutOptions}>
            <ReportAppContent />
        </JumboContentLayout>
    )
}

export default ReportAppLayout
