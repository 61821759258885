import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { updateIndicator } from 'app/redux/store/features/configs'
import { getSectionById, selectItemById, updateSection } from 'app/redux/store/features/sections'
import UpdateSectionForm from './UpdateSectionForm'

const UpdateSection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { classId, sectionId } = state
    const { sections, loading, error } = useSelector((state) => state.sections)
    const section = selectItemById(sections, sectionId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(section.sectionId)) {
            dispatch(getSectionById({ classId: classId, id: sectionId }))
        }
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Section updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            dispatch(updateIndicator({ inProgress: false }))
            navigate('/courses/setup/classes', { state: { navigateFrom: 'update-section' } })
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateSection = async () => {
        setApiCallInProgress(true)
        dispatch(updateIndicator({ inProgress: true }))
        dispatch(updateSection({ ...section }))
    }

    return (
        <React.Fragment>
            <UpdateSectionForm sectionData={section} updateSection={onUpdateSection} />
        </React.Fragment>
    )
}

export default UpdateSection
