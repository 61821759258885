import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { useSelector } from 'react-redux'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import IconTypeService from './IconTypeService'
import { PatternRule } from 'devextreme-react/data-grid'

const UpdateNotifyBarForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.notifybars)

    const iconTypeEditorOptions = {
        dataSource: IconTypeService.getIconTypeInfo(),
        displayExpr: 'iconType',
        valueExpr: 'id',
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/configuration/notify-bar'),
    }

    const validationRules = {
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        iconType: [{ type: 'required', message: 'Icon Type is required.' }],
    }

    const date = new Date(2018, 9, 16, 15, 8, 12)

    const navigationLinkEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }

    const navigationLinkPattern = /^(?=.{16,})https:\/\/.*$/

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Notify Bar
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateNotify)}>
                    <Form
                        mode='form'
                        formData={props.notifyBarData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Update Notify Bar' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='isActive' editorType='dxCheckBox'>
                                    <Label text='Enabled' />
                                </SimpleItem>
                                <SimpleItem dataField='heading' validationRules={validationRules.heading}>
                                    <Label text='Heading' />
                                </SimpleItem>
                                <SimpleItem dataField='subText'>
                                    <Label text='Sub Text' />
                                </SimpleItem>
                                <SimpleItem dataField='sideText'>
                                    <Label text='Side Text' />
                                </SimpleItem>
                                <SimpleItem dataField='orderBy'>
                                    <Label text='Sort Order' />
                                </SimpleItem>
                                <SimpleItem dataField='navigationLink' editorOptions={navigationLinkEditorOptions}>
                                    <Label text='Navigation Link' />
                                    <PatternRule
                                        message='navigation link must start with https:// and be at least 8 characters'
                                        pattern={navigationLinkPattern}
                                    />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='iconType'
                                    editorType='dxSelectBox'
                                    editorOptions={iconTypeEditorOptions}
                                    validationRules={validationRules.iconType}>
                                    <Label text='Icon Type' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='dateStart'
                                    editorType='dxDateBox'
                                    editorOptions={{
                                        defaultValue: date,
                                        placeholder: 'Tuesday, 16 of Oct, 2018 14:52',
                                        showClearButton: true,
                                        useMaskBehavior: true,
                                        displayFormat: 'EEEE, d of MMM, yyyy HH:mm',
                                    }}
                                    validationRules={validationRules.dateStart}
                                />
                                <SimpleItem
                                    dataField='dateEnd'
                                    editorType='dxDateBox'
                                    editorOptions={{
                                        defaultValue: date,
                                        placeholder: 'Tuesday, 16 of Oct, 2018 14:52',
                                        showClearButton: true,
                                        useMaskBehavior: true,
                                        displayFormat: 'EEEE, d of MMM, yyyy HH:mm',
                                    }}
                                    validationRules={validationRules.dateEnd}
                                />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateNotifyBarForm
