export const defaultInfoBarData = {
    dateStart: '',
    dateEnd: '',
    orderBy: '1',
    file: '',
    heading: '',
    subText: '',
    navigationLink: '',
    timeText: '',
}
