import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import ConfigForm from './ConfigForm'

const Config = () => {
    const navigate = useNavigate()
    const { loading, error } = useSelector((state) => state.configs)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Global Config updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/configuration/config')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <React.Fragment>
            <ConfigForm setApiCallInProgress={setApiCallInProgress} />
        </React.Fragment>
    )
}

export default Config
