if (!String.prototype.toFullName) {
    String.prototype.toFullName = function (lastName) {
        if (this === undefined || this === null) return ''
        if (lastName === undefined || lastName === null) return this
        return this + ' ' + lastName
    }
}

if (!String.prototype.toFamilyContactName) {
    String.prototype.toFamilyContactName = function (contactName) {
        if (this === undefined || this === null) return ''
        if (contactName === undefined || contactName === null) return this
        return this + ', ' + contactName
    }
}

if (!String.prototype.toFilterStartDate) {
    String.prototype.toFilterStartDate = function () {
        const parsedDate = new Date(this)
        if (isNaN(parsedDate.getTime())) {
            throw new Error(`Invalid date string: ${this}`)
        }
        parsedDate.setHours(0, 0, 0, 0)
        return parsedDate
    }
}

if (!String.prototype.toFilterEndDate) {
    String.prototype.toFilterEndDate = function () {
        const parsedDate = new Date(this)
        if (isNaN(parsedDate.getTime())) {
            throw new Error(`Invalid date string: ${this}`)
        }
        parsedDate.setHours(23, 59, 59, 999)
        return parsedDate
    }
}

const utilServices = {}

utilServices.emptyList = () => {
    return {
        totalCount: 0,
        data: [],
    }
}

utilServices.isNullOrUndefined = (value) => {
    return value === undefined || value === null
}
export default utilServices
