import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    notifybars: { data: [], totalCount: 0 },
    loading: false,
    refreshing: false,
    error: null,
}

const notifybarSlice = createSlice({
    name: 'notifybars',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getNotifybars: (state, action) => {
            state.notifybars = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingNotifybarById: (state, action) => {
            const index = state.notifybars.data.findIndex((notifybar) => notifybar.id === action.payload.id)
            if (index >= 0) state.notifybars[index] = action.payload
            else {
                state.notifybars.data.push(action.payload)
                state.notifybars.totalCount++
            }
            state.loading = false
        },
        addNewNotifybar: (state, action) => {
            state.notifybars.data.push(action.payload)
            state.notifybars.totalCount++
            state.loading = false
        },
        updateExistingNotifybar: (state, action) => {
            const index = state.notifybars.data.findIndex((notifybar) => notifybar.id === action.payload.id)
            if (index >= 0) state.notifybars[index] = action.payload
            state.loading = false
        },
        removeNotifybar: (state, action) => {
            const index = state.notifybars.data.findIndex((notifybar) => notifybar.id === action.payload.id)
            if (index >= 0) {
                state.notifybars.data.splice(index, 1)
                state.notifybars.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getNotifybars,
    getExistingNotifybarById,
    addNewNotifybar,
    updateExistingNotifybar,
    removeNotifybar,
} = notifybarSlice.actions
export default notifybarSlice.reducer

export const getNotifybarList = (filter, refresh = false) => {
    let url = `/api/Notify/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getNotifybars.type,
        onError: apiRequestedFailed.type,
    })
}

export const getNotifybarById = (filter) => {
    let url = `/api/Notify/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingNotifybarById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addNotifybar = (notifybar) => {
    let url = `/api/Notify/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: notifybar,
        onStart: apiRequested.type,
        onSuccess: addNewNotifybar.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateNotifybar = (notifybar) => {
    let url = `/api/Notify/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: notifybar,
        onStart: apiRequested.type,
        onSuccess: updateExistingNotifybar.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteNotifybar = (notifybar) => {
    let url = '/api/Notify/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: notifybar,
        onStart: apiRequested.type,
        onSuccess: removeNotifybar.type,
        onError: apiRequestedFailed.type,
    })
}
