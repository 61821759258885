import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar'
import shadows from '@mui/material/styles/shadows'
import { alpha } from '@mui/material/styles'
import { auth } from '../../services/auth/firebase/firebase'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { useAuthSignInWithEmailAndPassword } from '@react-query-firebase/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import Div from '@jumbo/shared/Div'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { useSnackbar } from 'notistack'
import { getTermList } from '../../redux/store/features/terms'
import { useDispatch } from 'react-redux'
import { getTermDashboard } from '../../redux/store/features/dashboard'

const validationSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    password: yup.string('Enter your password').required('Password is required'),
})

const SignIn = () => {
    const { setAuthToken } = useJumboAuth()
    const logInImgUrl = 'images/logo-web.png'
    const userImgUrl = 'images/ic_woman.png'
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const mutation = useAuthSignInWithEmailAndPassword(auth, {
        onError() {
            enqueueSnackbar('Login failed, please enter correct email and password.  ', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onSuccess(data) {
            setAuthToken(data.user.accessToken)
            dispatch(getTermDashboard({}))
        },
    })

    const onSignIn = (email, password) => {
        mutation.mutate({ email, password })
    }

    return (
        <Div
            sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '14%',
                marginBottom: '3%',
                justifyContent: 'center',
                p: (theme) => theme.spacing(4),
            }}>
            <Div sx={{ mb: 3, display: 'inline-flex' }}>
                <Link href='#' underline='none' sx={{ display: 'inline-flex' }}></Link>
            </Div>
            <Card sx={{ width: 360 }}>
                <Div sx={{ position: 'relative', height: '150px' }}>
                    <CardMedia component='img' alt='Icentre WA Login Image' image={logInImgUrl} />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: (theme) => alpha(theme.palette.common.black, 0.1),
                            p: (theme) => theme.spacing(3),
                        }}>
                        <Typography
                            variant={'h2'}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0,
                            }}></Typography>
                    </Div>
                </Div>
                <CardContent sx={{ pt: 0 }}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: 'app-admin@icentrewa.com.au',
                            password: 'LPhNoxvxCydm4t21',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true)
                            onSignIn(data.email, data.password)
                            setSubmitting(false)
                        }}>
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Avatar
                                    alt='User Iamge'
                                    src={userImgUrl}
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: 'auto',
                                        boxShadow: shadows[3],
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                                <Div sx={{ mb: 3, mt: 1 }}>
                                    <JumboTextField fullWidth name='email' label='Email' />
                                </Div>
                                <Div sx={{ mb: 2, mt: 1 }}>
                                    <JumboTextField fullWidth name='password' label='Password' type='password' />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting || mutation.isLoading}>
                                    Login
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    )
}

export default SignIn
