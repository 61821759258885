import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    classTypes: { data: [], totalCount: 0 },
    classTypeLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const classTypeSlice = createSlice({
    name: 'classTypes',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getClassTypes: (state, action) => {
            state.classTypes = action.payload
            state.loading = false
            state.refreshing = false
        },
        getClassTypeLookup: (state, action) => {
            state.classTypeLookups = action.payload
            state.loading = false
        },
        getExistingClassTypeLookupById: (state, action) => {
            const index = state.classTypeLookups.findIndex((classType) => classType.id === action.payload.id)
            if (index >= 0) state.classTypeLookups[index] = action.payload
            else {
                state.classTypeLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingClassTypeById: (state, action) => {
            const index = state.classTypes.data.findIndex((classType) => classType.id === action.payload.id)
            if (index >= 0) state.classTypes[index] = action.payload
            else {
                state.classTypes.data.push(action.payload)
                state.classTypes.totalCount++
            }
            state.loading = false
        },
        addNewClassType: (state, action) => {
            state.classTypes.data.push(action.payload)
            state.classTypes.totalCount++
            state.loading = false
        },
        updateExistingClassType: (state, action) => {
            const index = state.classTypes.data.findIndex((classType) => classType.id === action.payload.id)
            if (index >= 0) state.classTypes[index] = action.payload
            state.loading = false
        },
        removeClassType: (state, action) => {
            const index = state.classTypes.data.findIndex((classType) => classType.id === action.payload.id)
            if (index >= 0) {
                state.classTypes.data.splice(index, 1)
                state.classTypes.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getClassTypes,
    getClassTypeLookup,
    getExistingClassTypeLookupById,
    getExistingClassTypeById,
    addNewClassType,
    updateExistingClassType,
    removeClassType,
} = classTypeSlice.actions
export default classTypeSlice.reducer

export const getClassTypeList = (filter, refresh = false) => {
    let url = `/api/Setting/classType/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getClassTypes.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassTypeLookupList = (filter) => {
    let url = filter?.classTypeName
        ? `/api/Setting/classType/lookup/list?search=${filter.classTypeName}`
        : `/api/Setting/classType/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getClassTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassTypeLookupById = (filter) => {
    let url = `/api/Setting/classType/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingClassTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassTypeById = (filter) => {
    let url = `/api/Setting/classType/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingClassTypeById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addClassType = (classType) => {
    let url = `/api/Setting/classType/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: classType,
        onStart: apiRequested.type,
        onSuccess: addNewClassType.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateClassType = (classType) => {
    let url = `/api/Setting/classType/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: classType,
        onStart: apiRequested.type,
        onSuccess: updateExistingClassType.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteClassType = (classType) => {
    let url = '/api/Setting/classType/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: classType,
        onStart: apiRequested.type,
        onSuccess: removeClassType.type,
        onError: apiRequestedFailed.type,
    })
}
