export const defaultCustomer = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    genderType: 0,
    birthDate: null,
    maxOutstandingAmount: 200,
}
