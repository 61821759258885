import React from 'react'
import Page from '@jumbo/shared/Page'
import UserGrid from '../pages/security/users'
import AddUser from 'app/pages/security/users/components/AddUser'
import UpdateUser from 'app/pages/security/users/components/UpdateUser'
import PermissionGrid from '../pages/security/permissions/Permissions'
import TeachersGrid from '../pages/security/teachers/TeachersGrid'
import AddTeacher from 'app/pages/security/teachers/components/AddTeacher'
import UpdateTeacher from 'app/pages/security/teachers/components/UpdateTeacher'

const securityRoutes = [
    {
        path: '/security/users',
        element: <Page component={UserGrid} />,
    },
    {
        path: '/security/users/add',
        element: <Page component={AddUser} />,
    },
    {
        path: '/security/users/edit/:userGridId',
        element: <Page component={UpdateUser} />,
    },
    {
        path: ['/security/teachers'],
        element: <Page component={TeachersGrid} />,
    },
    {
        path: '/security/teachers/add',
        element: <Page component={AddTeacher} />,
    },
    {
        path: '/security/teachers/edit/:teacherId',
        element: <Page component={UpdateTeacher} />,
    },
    {
        path: ['/security/permissions', '/security/permissions/:userTypeName'],
        element: <Page component={PermissionGrid} />,
    },
]

export default securityRoutes
