let RelationshipTypeService = {}

RelationshipTypeService.setRelationshipTypeList = [
    { id: 0, relationshipType: 'Default' },
    { id: 1, relationshipType: 'Parent' },
    { id: 2, relationshipType: 'Child' },
    { id: 3, relationshipType: 'Brother' },
    { id: 4, relationshipType: 'Sister' },
    { id: 5, relationshipType: 'Relative' },
    { id: 6, relationshipType: 'Friend' },
    { id: 10, relationshipType: 'Other' },
]

RelationshipTypeService.getRelationshipType = () => {
    return RelationshipTypeService.setRelationshipTypeList
}

RelationshipTypeService.getRelationshipTypeData = (row) => {
    const relationshipType = RelationshipTypeService.setRelationshipTypeList.find(
        (item) => item.id === row.relationshipType
    )
    return relationshipType ? relationshipType.genderType : ''
}

export default RelationshipTypeService
