import { useState, useEffect } from 'react'

const useDebouncedInput = (initialValue = '', delay = 300) => {
    const [inputValue, setInputValue] = useState(initialValue)
    const [debouncedValue, setDebouncedValue] = useState(initialValue)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(inputValue)
        }, delay)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [inputValue, delay])

    const handleInputChange = (e) => {
        setInputValue(e.event.target?.value ?? '')
    }

    return {
        value: inputValue,
        debouncedValue,
        onChange: handleInputChange,
    }
}

export default useDebouncedInput
