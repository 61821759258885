import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, {
    ButtonItem,
    SimpleItem,
    EmptyItem,
    ColCountByScreen,
    Label,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
} from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { addClass } from 'app/redux/store/features/classes'
import { useSnackbar } from 'notistack'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const AddClassForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit } = useForm()
    const { loading, error } = useSelector((state) => state.classes)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) props.handleError(error)
        else {
            enqueueSnackbar('Class added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate(`/courses/setup/classes`, { state: { navigateFrom: 'add-class' } })
        }
    }, [loading])

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate(`/courses/setup/classes`, { state: { navigateFrom: 'cancel-add-class' } }),
    }

    const validationRules = {
        className: [{ type: 'required', message: 'Class Name is required.' }],
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        termId: [{ type: 'required', message: 'Term is required.' }],
        subTitle: [{ type: 'required', message: 'Sub Title is required.' }],
        ageGroupId: [{ type: 'required', message: 'Age Group is required.' }],
        termInfo: [{ type: 'required', message: 'Term Info is required.' }],
        shortDesc: [{ type: 'required', message: 'Age Info is required.' }],
        description: [{ type: 'required', message: 'Description is required.' }],
        description2: [{ type: 'required', message: 'Description 2 is required.' }],
    }

    const CurrencyEditorOptions = {
        format: (data) => formatCurrency(data), // Pass the formatCurrency function directly
    }

    const onAddClass = useCallback(async () => {
        setApiCallInProgress(true)
        dispatch(addClass({ ...props.classData }))
    }, [props.classData])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Add Class
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(onAddClass)}>
                    <Form
                        mode='form'
                        formData={props.classData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem>
                            <TabbedItem>
                                <TabPanelOptions deferRendering={false} />
                                <Tab title=' New Class'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem caption='Details' cssClass='form-group-item'>
                                            <GroupItem cssClass='form-group-item-margin'>
                                                <SimpleItem
                                                    dataField='termId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={props.termEditorOptions}
                                                    validationRules={validationRules.termId}>
                                                    <Label text='Term' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='locationId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={props.locationEditorOptions}>
                                                    <Label text='Location Name' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='className'
                                                    validationRules={validationRules.className}
                                                />
                                                <SimpleItem
                                                    dataField='classTypeId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={props.classTypeEditorOptions}>
                                                    <Label text='Class Type' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    editorType='dxSelectBox'
                                                    dataField='classGenderType'
                                                    editorOptions={props.genderEditorOptions}
                                                />
                                                <SimpleItem
                                                    dataField='subTitle'
                                                    validationRules={validationRules.subTitle}
                                                />
                                                <SimpleItem
                                                    editorType='dxSelectBox'
                                                    dataField='ageGroupId'
                                                    validationRules={validationRules.ageGroupId}
                                                    editorOptions={props.ageGroupEditorOptions}>
                                                    <Label text='Age Group' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='shortDesc'
                                                    validationRules={validationRules.shortDesc}>
                                                    <Label text='Age Info' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='termInfo'
                                                    validationRules={validationRules.termInfo}>
                                                    <Label text='Term Info' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='classPrice'
                                                    editorType='dxNumberBox'
                                                    editorOptions={CurrencyEditorOptions}>
                                                    <Label text='Class Price' />
                                                </SimpleItem>
                                                <SimpleItem dataField='comments' editorType='dxTextArea' />
                                                <SimpleItem dataField='canDeposit' editorType='dxCheckBox'>
                                                    <Label text='Deposit Enable' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    editorType='dxNumberBox'
                                                    dataField='depositAmount'
                                                    editorOptions={CurrencyEditorOptions}>
                                                    <Label text='Deposit Amount' />
                                                </SimpleItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <GroupItem caption='Configuration' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='dateStart'
                                                        editorType='dxDateBox'
                                                        validationRules={validationRules.dateStart}
                                                    />
                                                    <SimpleItem
                                                        dataField='dateEnd'
                                                        editorType='dxDateBox'
                                                        validationRules={validationRules.dateEnd}
                                                    />
                                                    <SimpleItem
                                                        dataField='newEnrolDays'
                                                        editorType='dxNumberBox'
                                                        editorOptions={{ showSpinButtons: 'true' }}>
                                                        <Label text='Delay New Enrol (Days after start date)' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='reEnrolDays'
                                                        editorType='dxNumberBox'
                                                        editorOptions={{ showSpinButtons: 'true' }}>
                                                        <Label text='Open ReEnrol (Days before end date)' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='prvClassId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.prvClassEditorOptions}>
                                                        <Label text='ReEnrol From Class (Last term)' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='teacherId'
                                                        editorType='dxSelectBox'
                                                        editorOptions={props.teacherEditorOptions}>
                                                        <Label text='Teacher Name' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='capacity'
                                                        editorType='dxNumberBox'
                                                        editorOptions={{ showSpinButtons: 'true' }}
                                                    />
                                                    <SimpleItem
                                                        dataField='txnDueDays'
                                                        editorType='dxNumberBox'
                                                        editorOptions={{ showSpinButtons: 'true' }}>
                                                        <Label text='Payment Due (Days)' />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='registerCode'>
                                                        <Label text='Register Code' />
                                                    </SimpleItem>
                                                    <SimpleItem
                                                        dataField='description'
                                                        editorType='dxTextArea'
                                                        validationRules={validationRules.description}
                                                    />
                                                    <SimpleItem
                                                        dataField='description2'
                                                        editorType='dxTextArea'
                                                        validationRules={validationRules.description2}
                                                    />
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddClassForm
