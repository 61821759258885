import { locale } from 'devextreme/localization'

export const formatDateTime = (input) => {
    const value = input && typeof input === 'object' ? input.value : input
    if (!value) return ''
    const date = new Date(value)
    const formattedDate = new Intl.DateTimeFormat(locale.languageTag, {
        timeStyle: 'medium',
        dateStyle: 'short',
    }).format(date)
    return <span>{formattedDate}</span>
}

export const formattedDate = (input, asElement = false) => {
    const value = input && typeof input === 'object' ? input.value : input
    if (!value) return asElement ? <span></span> : ''

    const date = new Date(value)
    const formattedDateString = new Intl.DateTimeFormat(locale.languageTag, {
        dateStyle: 'short',
    }).format(date)

    // Return as a React element or as a string based on the asElement flag
    return asElement ? <span>{formattedDateString}</span> : formattedDateString
}

export const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleString([], {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}
