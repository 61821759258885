import React from 'react'
import Div from '@jumbo/shared/Div'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { Grid, useMediaQuery } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { TabContext, TabPanel } from '@mui/lab'
import SectionGrid from './components/section/SectionGrid'
import AllocatedGrid from './components/allocated/AllocatedGrid'
import PendingGrid from './components/pending/PendingGrid'
import { useJumboTheme } from '@jumbo/hooks'

const PlacementGridLayout = () => {
    const [tabTitle, setTabTitle] = React.useState('pending')
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                    Placements
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
            }}>
            <Div sx={{ m: 1 }}>
                {min ? (
                    // Mobile layout: Stack FamilyGrid and MemberGrid vertically
                    <Grid container direction='column' spacing={2}>
                        <Grid item sx={{ width: '100%' }}>
                            <SectionGrid />
                        </Grid>
                        <Grid item sx={{ width: '100%' }}>
                            <TabContext value={tabTitle}>
                                <TabList
                                    sx={{ bgcolor: 'background.default', marginLeft: '2.4%', marginRight: '2.4%' }}
                                    onChange={(e, newValue) => setTabTitle(newValue)}>
                                    <Tab
                                        label={'Allocated'}
                                        value={'allocated'}
                                        sx={{
                                            width: '160px',
                                            textTransform: 'capitalize',
                                        }}
                                    />
                                    <Tab
                                        label={'Pending'}
                                        value={'pending'}
                                        sx={{ width: '160px', textTransform: 'capitalize' }}
                                    />
                                </TabList>
                                <TabPanel value='allocated' sx={{ marginLeft: '-15px', width: '400px' }}>
                                    <AllocatedGrid />
                                </TabPanel>
                                <TabPanel value='pending' sx={{ marginLeft: '-15px', width: '400px' }}>
                                    <PendingGrid />
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </Grid>
                ) : (
                    // Desktop layout: Sidebar with FamilyGrid and MemberGrid in content area
                    <JumboContentLayout
                        layoutOptions={{
                            sidebar: { sx: { mr: 2, width: '21%' } },
                        }}>
                        <Div style={{ display: 'flex' }}>
                            <Grid sx={{ width: '35%' }}>
                                <SectionGrid />
                            </Grid>
                            <Grid sx={{ width: '65%', m: 0.8 }}>
                                <TabContext value={tabTitle}>
                                    <TabList
                                        sx={{ bgcolor: 'background.default', marginLeft: '2.4%', marginRight: '2.4%' }}
                                        onChange={(e, newValue) => setTabTitle(newValue)}>
                                        <Tab
                                            label={'Allocated'}
                                            value={'allocated'}
                                            sx={{
                                                width: '160px',
                                                textTransform: 'capitalize',
                                            }}
                                        />
                                        <Tab
                                            label={'Pending'}
                                            value={'pending'}
                                            sx={{ width: '160px', textTransform: 'capitalize' }}
                                        />
                                    </TabList>
                                    <TabPanel value='allocated'>
                                        <AllocatedGrid />
                                    </TabPanel>
                                    <TabPanel value='pending'>
                                        <PendingGrid />
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Div>
                    </JumboContentLayout>
                )}
            </Div>
        </JumboCardQuick>
    )
}

export default PlacementGridLayout
