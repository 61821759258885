import React from 'react'
import { Avatar, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import Chip from '@mui/material/Chip'
import Span from '@jumbo/shared/Span'
import { useNavigate } from 'react-router-dom'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const LeftInfoPanelItem = ({ item }) => {
    const navigate = useNavigate()

    const handleTxnNumberClick = ({ item }) => {
        navigate(`/registrations/transactions/details/${item.id}`, { state: { id: item.id } })
    }

    return (
        <ListItemButton
            component={'li'}
            sx={{ p: (theme) => theme.spacing(1, 3) }}
            onClick={() => handleTxnNumberClick({ item })}>
            <ListItemAvatar>
                <Avatar alt={item.name} sx={{ color: 'common.white', bgcolor: `${item.bgcolor}` }}>
                    {item.icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                sx={{
                    flexBasis: '30%',
                }}
                primary={
                    <Typography variant='h5' mb={0.5} color='text.primary'>
                        <Chip label={item.className} size={'small'} color={'success'} sx={{ mb: 1 }} />
                        <br />
                        {item.familyName}
                    </Typography>
                }
                secondary={
                    <Typography variant={'body1'} color={'text.secondary'} mb={0.5} mr={1}>
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.phoneNumber}
                        </Span>{' '}
                        <br />
                        <Span sx={{ color: 'text.primary', mr: 1, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {item.email}
                        </Span>
                        <br />
                        <Span sx={{ color: 'text.primary', mr: 1 }}>{item.contactName}</Span>
                        <br />
                    </Typography>
                }
            />
            <ListItemText
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13, mr: 1 }}>
                        {item.txnNumber}
                    </Typography>
                }
            />
            <ListItemText
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13 }} color='text.primary'>
                        {formatDateTime(item.dateTxn)}
                    </Typography>
                }
            />
            <ListItemText
                primary={
                    <Typography variant='body1' sx={{ fontSize: 13, mr: 4 }} color='text.primary'>
                        {formatCurrency(item.amount)}
                    </Typography>
                }
                secondary={'Total'}
            />
            <ListItemText
                primary={
                    <Typography variant='body1' component={'span'} color='text.primary'>
                        {formatCurrency(item.payment)}
                    </Typography>
                }
                secondary={'paid'}
            />
        </ListItemButton>
    )
}

export default LeftInfoPanelItem
