const reportServices = {}

reportServices.getEnrolmentReportList = async () => {
    return {
        reports: [
            {
                id: 'enrolment-list-report',
                name: 'Enrolment List Report',
                category: 'enrolment',
                description: 'This report contains sales summary info',
            },
        ],
    }
}

reportServices.getPaymentReportList = async () => {
    return {
        reports: [
            {
                id: 'payment-list-report',
                name: 'Payment List Report',
                category: 'payment',
                description: 'This report includes purchase order summary information',
            },
        ],
    }
}
export default reportServices
