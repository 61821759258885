import React from 'react'
import SignOut from '../pages/profile/signOut/SignOut'
import Page from '@jumbo/shared/Page'
import EditProfile from 'app/pages/profile/editProfile/components/EditProfile'

const profileRoutes = [
    {
        path: '/profile/editprofile',
        element: <Page component={EditProfile} />,
    },
    {
        path: '/profile/signout',
        element: <Page component={SignOut} />,
    },
]

export default profileRoutes
