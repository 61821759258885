import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    classes: { data: [], totalCount: 0 },
    classLookups: [],
    classConfig: { classId: null },
    classSearchImage: { hasImage: false, filePath: '' },
    classDetailImage: { hasImage: false, filePath: '' }, //
    loading: false,
    error: null,
}

const classSlice = createSlice({
    name: 'classes',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getClassConfig: (state, action) => {
            state.classConfig = action.payload
        },
        updateClassConfig: (state, action) => {
            state.classConfig = action.payload
        },

        getSearchImg: (state, action) => {
            state.classSearchImage = action.payload
            state.loading = false
        },
        addSearchImg: (state, action) => {
            state.classSearchImage = action.payload
            state.loading = false
        },
        deleteSearchImg: (state, action) => {
            state.classSearchImage = action.payload
            state.loading = false
        },
        getDetailImg: (state, action) => {
            state.classDetailImage = action.payload
            state.loading = false
        },
        addDetailImg: (state, action) => {
            state.classDetailImage = action.payload
            state.loading = false
        },
        deleteDetailImg: (state, action) => {
            state.classDetailImage = action.payload
            state.loading = false
        },
        getClasses: (state, action) => {
            state.classes = action.payload
            state.loading = false
            state.refreshing = false
        },
        getClassLookup: (state, action) => {
            state.classLookups = action.payload
            state.loading = false
        },
        getExistingClassLookupById: (state, action) => {
            const index = state.classLookups.findIndex((clas) => clas.id === action.payload.id)
            if (index >= 0) state.classLookups[index] = action.payload
            else {
                state.classLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingClassById: (state, action) => {
            const index = state.classes.data.findIndex((clas) => clas.id === action.payload.id)
            if (index >= 0) state.classes[index] = action.payload
            else {
                state.classes.data.push(action.payload)
                state.classes.totalCount++
            }

            state.loading = false
        },
        duplicateExistingClass: (state, action) => {
            state.classes.data.push(action.payload)
            state.classes.totalCount++
            state.loading = false
        },
        addNewClass: (state, action) => {
            state.classes.data.push(action.payload)
            state.classes.totalCount++
            state.loading = false
        },
        updateExistingClass: (state, action) => {
            const index = state.classes.data.findIndex((clas) => clas.id === action.payload.id)
            if (index >= 0) state.classes[index] = action.payload
            state.loading = false
        },
        removeClass: (state, action) => {
            const index = state.classes.data.findIndex((clas) => clas.id === action.payload.id)
            if (index >= 0) {
                state.classes.data.splice(index, 1)
                state.classes.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getClassConfig,
    getSearchImg,
    addSearchImg,
    deleteSearchImg,
    getDetailImg,
    addDetailImg,
    deleteDetailImg,
    updateClassConfig,
    getClasses,
    getClassLookup,
    getExistingClassLookupById,
    getExistingClassById,
    duplicateExistingClass,
    addNewClass,
    updateExistingClass,
    removeClass,
} = classSlice.actions
export default classSlice.reducer

export const getSearchImage = (filter) => {
    let url = `/api/Class/image/search/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSearchImg.type,
        onError: apiRequestedFailed.type,
    })
}

export const addSearchImage = (image, headers) => {
    let url = `/api/Class/image/search/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: image,
        headers: headers,
        onStart: apiRequested.type,
        onSuccess: addSearchImg.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteSearchImage = (image) => {
    let url = `/api/Class/image/search/delete/${image.id}`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: image,
        onStart: apiRequested.type,
        onSuccess: deleteSearchImg.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDetailImage = (filter) => {
    let url = `/api/Class/image/detail/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getDetailImg.type,
        onError: apiRequestedFailed.type,
    })
}

export const addDetailImage = (image, headers) => {
    console.log('image = ', image)
    let url = `/api/Class/image/detail/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: image,
        headers: headers,
        onStart: apiRequested.type,
        onSuccess: addDetailImg.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteDetailImage = (image) => {
    let url = `/api/Class/image/detail/delete/${image.id}`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: image,
        onStart: apiRequested.type,
        onSuccess: deleteDetailImg.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassList = (filter, refresh = false) => {
    let url = `/api/Class/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getClasses.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassLookupList = (filter) => {
    let url = filter?.className
        ? `/api/Class/lookup/list/term/${filter.termId}?search=${filter.className}`
        : `/api/Class/lookup/list/term/${filter.termId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getClassLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassLookupById = (filter) => {
    let url = `/api/Class/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingClassLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getClassById = (filter) => {
    let url = `/api/Class/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingClassById.type,
        onError: apiRequestedFailed.type,
    })
}

export const duplicateClass = (clas) => {
    let url = `/api/Class/duplicate`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: clas,
        onStart: apiRequested.type,
        onSuccess: duplicateExistingClass.type,
        onError: apiRequestedFailed.type,
    })
}

export const addClass = (clas) => {
    let url = `/api/Class/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: clas,
        onStart: apiRequested.type,
        onSuccess: addNewClass.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateClass = (clas) => {
    let url = `/api/Class/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: clas,
        onStart: apiRequested.type,
        onSuccess: updateExistingClass.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteClass = (clas) => {
    let url = `/api/Class/delete`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: clas,
        onStart: apiRequested.type,
        onSuccess: removeClass.type,
        onError: apiRequestedFailed.type,
    })
}
