import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import '../../configuration.css'
import { PatternRule } from 'devextreme-react/data-grid'

const UpdateInfoBarForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.infobars)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/configuration/info-bar'),
    }

    const validationRules = {
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        heading: [{ type: 'required', message: 'Heading is required.' }],
        subText: [{ type: 'required', message: 'Sub-Text is required.' }],
        timeText: [{ type: 'required', message: 'Time Text is required.' }],
    }

    const editorOptionsView = {
        readOnly: true,
    }

    const navigationLinkEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }

    const navigationLinkPattern = /^(?=.{16,})https:\/\/.*$/

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Info Bar
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateInfoBar)}>
                    <Form
                        mode='form'
                        formData={props.infoBarData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Update Info Bar' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='isActive' editorType='dxCheckBox'>
                                    <Label text='Enabled' />
                                </SimpleItem>
                                <SimpleItem dataField='heading' validationRules={validationRules.heading}>
                                    <Label text='Heading' />
                                </SimpleItem>
                                <SimpleItem dataField='subText' validationRules={validationRules.subText}>
                                    <Label text='Sub Text' />
                                </SimpleItem>
                                <SimpleItem dataField='timeText' validationRules={validationRules.timeText}>
                                    <Label text='Time Text' />
                                </SimpleItem>
                                <SimpleItem dataField='orderBy'>
                                    <Label text='Sort Order' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='dateStart'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.dateStart}
                                />
                                <SimpleItem
                                    dataField='dateEnd'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.dateEnd}
                                />
                                <SimpleItem dataField='navigationLink' editorOptions={navigationLinkEditorOptions}>
                                    <Label text='Navigation Link' />
                                    <PatternRule
                                        message='navigation link must start with https:// and be at least 8 characters'
                                        pattern={navigationLinkPattern}
                                    />
                                </SimpleItem>
                                <SimpleItem>
                                    <div className='image-container'>
                                        <img src={props.infoBarData.filePath} alt='Preview' className='image-preview' />
                                    </div>
                                </SimpleItem>
                                <SimpleItem dataField='fileName' editorOptions={editorOptionsView}>
                                    <Label text='File Name' />
                                </SimpleItem>
                                <SimpleItem dataField='fileSize' editorOptions={editorOptionsView}>
                                    <Label text='Size' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateInfoBarForm
