import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import vfs from '../../../../../../../fonts/NimbusSanL/nimbus-san'
import PaymentListReportFilter from './PaymentListReportFilter'
import { formattedDate } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const PaymentListReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
    pdfData,
}) => {
    const { paymentListReport, loading, error } = useSelector((state) => state.reports)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const [pdfTemplate, setpdfTemplate] = useState({})
    const itemsPerPage = 25
    const totalPages = Math.ceil(paymentListReport.totalCount / itemsPerPage)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }

    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    const headers = React.useMemo(
        () => [
            'Register Date',
            'Customer Name',
            'Phone No',
            'Email',
            'Term',
            'Class',
            'Total Amount',
            'Paid Amount',
            'Balance',
            'Over Due',
        ],
        []
    )

    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    formattedDate(d.dateTxn),
                    `${d.firstName} ${d.lastName}`,
                    d?.phoneNumber,
                    d?.email,
                    `${d.termYear} / ${d.termNumber}`,
                    d?.className,
                    formatCurrency(d?.totalAmount),
                    formatCurrency(d?.amountPaid),
                    formatCurrency(d?.balance),
                    d.overDue ? 'Yes' : 'No',
                ]
            })
        },
        []
    )

    const pageRow = []

    const tableBody =
        pdfData?.data?.length > 0
            ? parseToPdfData(pdfData.data).map((row) =>
                  row.map((cell, index) => ({
                      text: cell,
                      style: [
                          'cell',
                          index === 0 || index === 1 || index === 2 || index === 3 || index === 5
                              ? 'leftAlign'
                              : index === 4 || index === 9
                              ? 'center'
                              : 'rightAlign',
                      ],
                  }))
              )
            : []

    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 45, 15, 45],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [260, 80, 120, 110, 110, 75, 95, 80, 85, 90],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [15, 10, 15, 10],
                            },
                        ],
                    }
                }

                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [260, 80, 120, 110, 110, 75, 95, 80, 85, 90],
                                body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                            },
                            margin: [15, 5, 15, 20],
                        },
                    ],
                }
            },
            content: [
                {
                    image: imageData,
                    width: 70,
                },
                {
                    text: 'Payment List Report',
                    style: 'header',
                    alignment: 'center',
                },

                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    style: 'withMargin',
                    alignment: 'center',
                    table: {
                        headerRows: 1,
                        widths: [75, 90, 70, 125, 50, 105, 50, 50, 50, 50],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...tableBody,
                        ],
                    },
                },
            ],
            defaultStyle: {
                font: 'NimbusSans',
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                centerAlign: {
                    alignment: 'center',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, pdfData])

    useEffect(() => {
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, pdfData, pdfTemplateDefault])

    const create = async () => {
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(`Payment_List_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`)
    }, [pdfTemplate])

    return (
        <Div>
            <h1 className='report-heading'>Payment List Report</h1>
            <LoadingButton
                sx={{ float: 'right', marginRight: '90px', marginTop: '-65px' }}
                onClick={create}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
            </LoadingButton>
            <LoadingButton
                sx={{ float: 'right', marginRight: '9px', marginTop: '-65px' }}
                onClick={handleFilterClick}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <FilterAltIcon fontSize='medium' />
            </LoadingButton>
            {isFilterVisible && <PaymentListReportFilter mutation={viewMutation} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && paymentListReport.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <div className='custom-table'>
                            <table>
                                <tbody>
                                    <tr>
                                        {headers.map((h, index) => {
                                            const isLastSix = index >= headers.length - 4
                                            const isCenter = index == 4
                                            return (
                                                <th
                                                    style={{
                                                        whiteSpace:
                                                            index === 6 || index === 7 || index === 8 || index === 9
                                                                ? 'pre'
                                                                : 'pre-line',
                                                    }}
                                                    key={h}>
                                                    {h}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {paymentListReport.data?.map((d, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: '120px', textAlign: 'left' }}>
                                                    {formattedDate(d.dateTxn)}
                                                </td>
                                                <td
                                                    style={{
                                                        width: '100px',
                                                        textAlign: 'left',
                                                    }}>{`${d.firstName} ${d.lastName}`}</td>
                                                <td style={{ width: '20px', textAlign: 'left' }}>{d?.phoneNumber}</td>
                                                <td style={{ width: '20px', textAlign: 'left' }}>{d?.email}</td>
                                                <td
                                                    style={{
                                                        width: '75px',
                                                        textAlign: 'center',
                                                    }}>{`${d.termYear} / ${d.termNumber}`}</td>
                                                <td style={{ width: '140px', textAlign: 'left' }}>{d?.className}</td>
                                                <td style={{ width: '65px', textAlign: 'right' }}>${d?.totalAmount}</td>
                                                <td style={{ width: '65px', textAlign: 'right' }}>${d?.amountPaid}</td>
                                                <td style={{ width: '65px', textAlign: 'right' }}>${d?.balance}</td>
                                                <td style={{ width: '65px', textAlign: 'center' }}>
                                                    {d.overDue ? 'Yes' : 'No'}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    <tr>
                                        <td colSpan={headers.length} />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Stack>
                        <Pagination
                            count={Math.ceil(paymentListReport.totalCount / itemsPerPage)}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default PaymentListReportLayout
