import { USE_IMAGE_PLACEHOLDERS } from './constants/paths'

export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`
    }

    return url
}
export const isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== ''
}

export const buildQuery = (baseUrl, userQuery) => {
    const query = []
    for (const key in userQuery) {
        if (userQuery[key]) query.push(encodeURIComponent(key) + '=' + encodeURIComponent(userQuery[key]))
    }
    return baseUrl + (query.length ? '?' + query.join('&') : '')
}
