import React from 'react'
import Dashboard from '../pages/home'

import Page from '@jumbo/shared/Page'

const dashboardRoutes = [
    {
        path: '/home/dashboard',
        element: <Page component={Dashboard} />,
    },
]

export default dashboardRoutes
