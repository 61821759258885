import React from 'react'
import Div from '@jumbo/shared/Div'
import Link from '@mui/material/Link'

const Logo = ({ mini, mode, sx }) => {
    const logoUrl = '/images/logo-web.png'
    return (
        <Div sx={{ display: 'inline-flex', ...sx }}>
            <Link href={'/dashboards/misc'}>
                <img src={logoUrl} alt='Islamic Centre of WA' width='150' />
            </Link>
        </Div>
    )
}

Logo.defaultProps = {
    mode: 'light',
}

export default Logo
