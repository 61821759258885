import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Typography, useMediaQuery, Tabs, Tab, Box } from '@mui/material'
import LocationsGrid from './components/locations'
import ClassTypeGrid from './components/classTypes/ClassTypeGrid'
import AgeGroupGrid from './components/ageGroup/AgeGroupGrid'
import Div from '@jumbo/shared/Div'
import { useJumboTheme } from '@jumbo/hooks'

function Configuration() {
    const navigate = useNavigate()
    const { selectedTab } = useParams()
    const [tabTitle, setTabTitle] = React.useState(selectedTab ? selectedTab : 'locations')
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
        navigate(`/settings/definitions/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Definitions
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <Tabs
                    value={tabTitle}
                    onChange={onTabSelectionChanged}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                    sx={{
                        bgcolor: 'background.default',
                        mt: 2,
                        mb: 0,
                        ml: 3.1,
                        mr: 3.1,
                        '.MuiTabs-scrollButtons': {
                            // Styling for scroll buttons
                            '&.Mui-disabled': {
                                opacity: 0.3, // Ensure disabled buttons are visible but faded
                            },
                        },
                    }}>
                    <Tab
                        label='Locations'
                        value='locations'
                        sx={{
                            width: min ? '125px' : '160px',
                            textTransform: 'capitalize',
                        }}
                    />
                    <Tab
                        label='Class Types'
                        value='class-types'
                        sx={{
                            width: min ? '125px' : '160px',
                            textTransform: 'capitalize',
                        }}
                    />
                    <Tab
                        label='Age Group'
                        value='age-group'
                        sx={{
                            width: min ? '125px' : '160px',
                            textTransform: 'capitalize',
                        }}
                    />
                </Tabs>
                <Div>
                    {tabTitle === 'locations' && (
                        <Box p={3}>
                            <LocationsGrid />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'class-types' && (
                        <Box p={3}>
                            <ClassTypeGrid />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'age-group' && (
                        <Box p={3}>
                            <AgeGroupGrid />
                        </Box>
                    )}
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default Configuration
