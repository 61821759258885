import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    sections: { data: [], totalCount: 0 },
    sectionLookups: [],
    sectionConfig: { sectionId: null },
    loading: false,
    refreshing: false,
    error: null,
}

const sectionSlice = createSlice({
    name: 'sections',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getSectionConfig: (state, action) => {
            state.sectionConfig = action.payload
        },
        updateSectionConfig: (state, action) => {
            state.sectionConfig = action.payload
        },
        getSections: (state, action) => {
            state.sections = action.payload
            state.loading = false
            state.refreshing = false
        },
        clearSections: (state, action) => {
            state.sections = { data: [], totalCount: 0 }
            state.loading = false
        },
        getSectionLookup: (state, action) => {
            state.sectionLookups = action.payload
            state.loading = false
        },
        clearSectionLookup: (state, action) => {
            state.sectionLookups = []
            state.loading = false
        },
        getExistingSectionLookupById: (state, action) => {
            const index = state.sectionLookups.findIndex((section) => section.id === action.payload.id)
            if (index >= 0) state.sectionLookups[index] = action.payload
            else {
                state.sectionLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingSectionById: (state, action) => {
            const index = state.sections.data.findIndex((section) => section.id === action.payload.id)
            if (index >= 0) state.sections[index] = action.payload
            else {
                state.sections.data.push(action.payload)
                state.sections.totalCount++
            }
            state.loading = false
        },
        addNewSection: (state, action) => {
            state.sections.data.push(action.payload)
            state.sections.totalCount++
            state.loading = false
        },
        updateExistingSection: (state, action) => {
            const index = state.sections.data.findIndex((section) => section.id === action.payload.id)
            if (index >= 0) state.sections[index] = action.payload
            state.loading = false
        },
        removeSection: (state, action) => {
            const index = state.sections.data.findIndex((section) => section.id === action.payload.id)
            if (index >= 0) {
                state.sections.data.splice(index, 1)
                state.sections.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getSectionConfig,
    updateSectionConfig,
    getSections,
    clearSections,
    getSectionLookup,
    clearSectionLookup,
    getExistingSectionLookupById,
    getExistingSectionById,
    addNewSection,
    updateExistingSection,
    removeSection,
} = sectionSlice.actions
export default sectionSlice.reducer

export const getSectionList = (filter, refresh = false) => {
    let url = `/api/Class/section/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getSections.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSectionLookupList = (filter) => {
    let url = filter?.sectionName
        ? `/api/Class/${filter.classId}/section/lookup/list?search=${filter.sectionName}`
        : `/api/Class/${filter.classId}/section/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSectionLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSectionLookupById = (filter) => {
    let url = `/api/Class/${filter.classId}/section/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSectionLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSectionById = (filter) => {
    let url = `/api/Class/${filter.classId}/section/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSectionById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addSection = (section) => {
    let url = `/api/Class/section/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: section,
        onStart: apiRequested.type,
        onSuccess: addNewSection.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateSection = (section) => {
    let url = `/api/Class/section/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: section,
        onStart: apiRequested.type,
        onSuccess: updateExistingSection.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteSection = (section) => {
    let url = `/api/Class/section/delete`
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: section,
        onStart: apiRequested.type,
        onSuccess: removeSection.type,
        onError: apiRequestedFailed.type,
    })
}
