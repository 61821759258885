import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import teacherServices from 'app/services/pages/security/teacherServices/teacherServices'
import classesService from 'app/services/pages/courses/setup/classesServices/classesService'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import { useSnackbar } from 'notistack'

const UpdateClassSectionForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { enqueueSnackbar } = useSnackbar()
    const { termConfig } = useSelector((state) => state.terms)
    const { loading } = useSelector((state) => state.sections)

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const classLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await classesService.getClassLookupList({ termId: termConfig.termId, className: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [classesService, termConfig.termId]
    )

    const classLookupById = useCallback(
        async (key) => {
            try {
                return await classesService.getClassLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [classesService]
    )

    const classLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await classLookupList(searchTerm)
            },
            byKey: function (key) {
                if (key === '') return utilServices.emptyList()

                return classLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const classNameEditorOptions = {
        dataSource: classLookupsStore,
        readOnly: true,
        displayExpr: 'className',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const teacherLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await teacherServices.getTeacherLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [teacherServices]
    )

    const teacherLookupById = useCallback(
        async (key) => {
            try {
                return await teacherServices.getTeacherById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [teacherServices]
    )

    const teacherLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await teacherLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return teacherLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const teacherEditorOptions = {
        dataSource: teacherLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/courses/setup/classes', { state: { navigateFrom: 'update-section' } }),
    }

    const validationRules = {
        sectionName: [{ type: 'required', message: 'Section Name is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Section
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateSection)}>
                    <Form
                        mode='form'
                        formData={props.sectionData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='Update Section' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='classId'
                                    editorType='dxSelectBox'
                                    editorOptions={classNameEditorOptions}>
                                    <Label text='Class Name' />
                                </SimpleItem>
                                <SimpleItem dataField='sectionName' validationRules={validationRules.sectionName} />
                                <SimpleItem dataField='level' />
                                <SimpleItem
                                    dataField='capacity'
                                    editorType='dxNumberBox'
                                    editorOptions={{ showSpinButtons: 'true' }}
                                />
                                <SimpleItem
                                    dataField='teacherId'
                                    editorType='dxSelectBox'
                                    editorOptions={teacherEditorOptions}>
                                    <Label text='Teacher' />
                                </SimpleItem>
                                <SimpleItem dataField='description' editorType='dxTextArea' />
                                <SimpleItem dataField='comments' editorType='dxTextArea' />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateClassSectionForm
