import React from 'react'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import { Card, CardContent, ListItem, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import CategoryIcon from '@mui/icons-material/Category'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import PlaceIcon from '@mui/icons-material/Place'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import EmailIcon from '@mui/icons-material/Email'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import PersonIcon from '@mui/icons-material/Person'
import Span from '@jumbo/shared/Span'
import { RxValue } from 'react-icons/rx'

const LeftInfoCard = ({ transaction }) => {
    return (
        <Grid>
            <Card sx={{ height: 300 }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItem sx={{ mt: -8.1 }}>
                                    <ListItemText
                                        disablePadding
                                        primary={
                                            <Typography
                                                variant={'h5'}
                                                color='text.primary'
                                                fontFamily='Lobster, cursive'>
                                                <CategoryIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                                {transaction.termName ? (
                                                    <Span sx={{ color: 'secondary.dark' }}>{transaction.termName}</Span>
                                                ) : (
                                                    'Term Name'
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <PointOfSaleIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' '}
                                                {transaction.className ? (
                                                    <Span sx={{ color: 'secondary.dark' }}>
                                                        {transaction.className}
                                                    </Span>
                                                ) : (
                                                    'Class Name'
                                                )}
                                            </Span>{' '}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <HowToRegIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' '}
                                                {transaction.locationName ? (
                                                    <Span sx={{ color: 'secondary.dark' }}>
                                                        {transaction.locationName}
                                                    </Span>
                                                ) : (
                                                    'Location Name'
                                                )}
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sx={{ width: '100%', display: 'flex', alignItems: 'center', ml: 3.9 }}>
                            {' '}
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        {' '}
                                        <AccountBoxIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />{' '}
                                        {transaction.firstName + ' ' + transaction.lastName}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />

                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <ContactPhoneIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        {transaction.phoneNumber}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        {' '}
                                        <EmailIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        {transaction.email}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        {' '}
                                        <FamilyRestroomIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        {transaction.familyName}
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LeftInfoCard
