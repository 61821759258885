import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { defaultInfoBarData } from './defaultInfoBarData'
import dateFormat from 'dateformat'
import AddInfoBarForm from './AddInfoBarForm'
import { addInfobar } from 'app/redux/store/features/infobars'

const AddInfoBar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    let today = new Date()

    const [InfoBarData] = useState({
        ...defaultInfoBarData,
        dateStart: today.setDate(today.getDate()),
        dateEnd: today.setDate(today.getDate() + 2000),
    })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { infobars, loading, error } = useSelector((state) => state.infobars)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Info Bar added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/configuration/info-bar')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddInfoBar = useCallback((e) => {
        console.log(e)
        setApiCallInProgress(true)

        const form = new FormData()
        form.append('dateStart', dateFormat(InfoBarData.dateStart))
        form.append('dateEnd', dateFormat(InfoBarData.dateEnd))
        form.append('orderBy', InfoBarData.orderBy)
        form.append('image', InfoBarData.file[0])
        form.append('heading', InfoBarData.heading)
        form.append('subText', InfoBarData.subText)
        form.append('timeText', InfoBarData.timeText)
        form.append('navigationLink', InfoBarData.navigationLink)
        form.append('timeText', InfoBarData.timeText)

        e.preventDefault()
        dispatch(
            addInfobar(form, {
                'Content-Type': 'multipart/form-data',
            })
        )
    }, [])

    return (
        <React.Fragment>
            <AddInfoBarForm InfoBarData={InfoBarData} addInfoBar={onAddInfoBar} />
        </React.Fragment>
    )
}

export default AddInfoBar
