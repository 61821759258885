const util = {}

util.getNextFiveYear = () => {
    const currentYear = new Date().getFullYear() - 1;

    const years = [currentYear, currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4, currentYear + 5]

    return years
}
export default util
