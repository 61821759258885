import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    profile: {},
    loading: false,
    error: null,
}

const profileSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getProfile: (state, action) => {
            state.profile = action.payload
            state.loading = false
        },
        clearProfile: (state, action) => {
            state.profile = {}
            state.loading = false
        },
        updateProfile: (state, action) => {
            state.profile = action.payload
            state.loading = false
        },
        changePassword: (state, action) => {
            state.profile = action.payload
            state.loading = false
        },
    },
})

export const { apiRequested, apiRequestedFailed, getProfile, updateProfile, clearProfile, changePassword } =
    profileSlice.actions
export default profileSlice.reducer

export const getUserProfile = () => {
    let url = '/api/Profile'
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getProfile.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateUserProfile = (profile) => {
    let url = `/api/Profile/update`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: profile,
        onStart: apiRequested.type,
        onSuccess: updateProfile.type,
        onError: apiRequestedFailed.type,
    })
}

export const changeUserPassword = (profile) => {
    let url = `/api/Profile/password/change`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: profile,
        onStart: apiRequested.type,
        onSuccess: changePassword.type,
        onError: apiRequestedFailed.type,
    })
}
