import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { selectItemById, getAgeGroupById, updateAgeGroup } from 'app/redux/store/features/ageGroups'
import utilServices from 'app/services/util-services'
import UpdateAgeGroupForm from './UpdateAgeGroupForm'

const UpdateAgeGroup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { state } = useLocation()
    const { id } = state
    const { ageGroups, loading, error } = useSelector((state) => state.ageGroups)
    const ageGroup = selectItemById(ageGroups, id)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(ageGroup.id)) dispatch(getAgeGroupById({ id: id }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Age Group updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/definitions/age-group')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateAgeGroup = async () => {
        setApiCallInProgress(true)
        dispatch(updateAgeGroup({ ...ageGroup }))
    }

    return (
        <React.Fragment>
            <UpdateAgeGroupForm ageGroupData={ageGroup} updateAgeGroup={onUpdateAgeGroup} />
        </React.Fragment>
    )
}

export default UpdateAgeGroup
