import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { addClassType } from 'app/redux/store/features/classTypes'
import { defaultClassType } from './defaultClassType'
import AddClassTypeForm from './AddClassTypeForm'
const AddClassType = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [classTypeData] = useState({ ...defaultClassType })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.classTypes)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Class Type added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/definitions/class-types')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddClassType = async () => {
        setApiCallInProgress(true)
        dispatch(addClassType({ ...classTypeData }))
    }

    return (
        <React.Fragment>
            <AddClassTypeForm classTypeData={classTypeData} addClassType={onAddClassType} />
        </React.Fragment>
    )
}

export default AddClassType
