import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    global: {},
    store: {},
    indicator: { inProgress: false },
    loading: false,
    error: null,
}

const configSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getGlobalConfig: (state, action) => {
            state.global = action.payload
        },
        updateGlobal: (state, action) => {
            state.global = action.payload
        },

        getStoreConfig: (state, action) => {
            state.store = action.payload
        },
        updateStore: (state, action) => {
            state.store = action.payload
        },

        getIndicator: (state, action) => {
            state.indicator = action.payload
        },
        updateIndicator: (state, action) => {
            state.indicator = action.payload
        },
    },
})

export const {
    apiRequested,
    apiRequestedFailed,
    getGlobalConfig,
    updateGlobal,
    getStoreConfig,
    updateStore,
    getIndicator,
    updateIndicator,
} = configSlice.actions

export default configSlice.reducer

export const getGlobalConfigList = () => {
    let url = '/api/Config/global'
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getGlobalConfig.type,
        onError: apiRequestedFailed.type,
    })
}
export const updateGlobalConfig = (profile) => {
    let url = `/api/Config/global/update`
    return apiCallBegan({
        url: url,
        method: 'put',
        data: profile,
        onStart: apiRequested.type,
        onSuccess: updateGlobal.type,
        onError: apiRequestedFailed.type,
    })
}
