import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { defaultBannerBarData } from './defaultBannerBarData'
import AddBannerBarForm from './AddBannerBarForm'
import { addBanner } from 'app/redux/store/features/banners'
import dateFormat from 'dateformat'

const AddBannerBar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    let today = new Date()

    const [BannerBarData] = useState({
        ...defaultBannerBarData,
        dateStart: today.setDate(today.getDate()),
        dateEnd: today.setDate(today.getDate() + 2000),
    })
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.banners)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Banner Bar added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/configuration/banner-bar')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddBannerBar = useCallback((e) => {
        console.log(e)
        setApiCallInProgress(true)

        const form = new FormData()
        form.append('dateStart', dateFormat(BannerBarData.dateStart))
        form.append('dateEnd', dateFormat(BannerBarData.dateEnd))
        form.append('orderBy', BannerBarData.orderBy)
        form.append('image', BannerBarData.file[0])
        form.append('navigationLink', BannerBarData.navigationLink)
        form.append('subText', BannerBarData.subText)
        e.preventDefault()
        dispatch(
            addBanner(form, {
                'Content-Type': 'multipart/form-data',
            })
        )
    }, [])

    return (
        <React.Fragment>
            <AddBannerBarForm BannerBarData={BannerBarData} addBannerBar={onAddBannerBar} />
        </React.Fragment>
    )
}

export default AddBannerBar
