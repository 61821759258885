import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { selectItemById, updateTeacher, getTeacherById } from 'app/redux/store/features/teachers'
import UpdateTeacherForm from './UpdateTeacherForm'

const UpdateTeacher = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { state } = useLocation()
    const { teacherId } = state
    const { teachers, loading, error } = useSelector((state) => state.teachers)
    const teacher = selectItemById(teachers, teacherId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(teachers.id)) dispatch(getTeacherById({ id: teacherId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Teacher updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/security/teachers', { state: { navigateFrom: 'update-teacher' } })
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateTeacher = async () => {
        setApiCallInProgress(true)
        dispatch(updateTeacher({ ...teacher }))
    }

    return (
        <React.Fragment>
            <UpdateTeacherForm teacherData={teacher} updateTeacher={onUpdateTeacher} />
        </React.Fragment>
    )
}

export default UpdateTeacher
