import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    members: { data: [], totalCount: 0 },
    memberLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const memberSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getMembers: (state, action) => {
            state.members = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingMemberById: (state, action) => {
            const index = state.members.data.findIndex((member) => member.id === action.payload.id)
            if (index >= 0) state.members[index] = action.payload
            else {
                state.members.data.push(action.payload)
                state.members.totalCount++
            }
            state.loading = false
        },
        getMemberLookup: (state, action) => {
            state.memberLookups = action.payload
            state.loading = false
        },
        clearMemberLookup: (state, action) => {
            state.memberLookups = []
            state.loading = false
        },
        getExistingMemberLookupById: (state, action) => {
            const index = state.memberLookups.findIndex((member) => member.id === action.payload.id)
            if (index >= 0) state.memberLookups[index] = action.payload
            else {
                state.memberLookups.push(action.payload)
            }
            state.loading = false
        },
        addNewMember: (state, action) => {
            state.members.data.push(action.payload)
            state.members.totalCount++
            state.loading = false
        },
        updateExistingMember: (state, action) => {
            const index = state.members.data.findIndex((member) => member.id === action.payload.id)
            if (index >= 0) state.members[index] = action.payload
            state.loading = false
        },
        removeMember: (state, action) => {
            const index = state.members.data.findIndex((member) => member.id === action.payload.id)
            if (index >= 0) {
                state.members.data.splice(index, 1)
                state.members.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getMembers,
    getExistingMemberById,
    getMemberLookup,
    clearMemberLookup,
    getExistingMemberLookupById,
    addNewMember,
    updateExistingMember,
    removeMember,
} = memberSlice.actions
export default memberSlice.reducer

export const getMemberList = (filter, refresh = false) => {
    let url = `/api/Family/member/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getMembers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getMemberById = (filter) => {
    let url = `/api/Family/${filter.familyId}/member/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingMemberById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getMemberLookupList = (filter) => {
    let url = filter?.memberName
        ? `/api/Family/${filter.familyId}/member/lookup/list?search=${filter.memberName}`
        : `/api/Family/${filter.familyId}/member/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getMemberLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getMemberLookupById = (filter) => {
    let url = `/api/Family/${filter.familyId}/member/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingMemberLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addMember = (member) => {
    let url = `/api/Family/member/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: member,
        onStart: apiRequested.type,
        onSuccess: addNewMember.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateMember = (member) => {
    let url = `/api/Family/member/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: member,
        onStart: apiRequested.type,
        onSuccess: updateExistingMember.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteMember = (member) => {
    let url = '/api/Family/member/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: member,
        onStart: apiRequested.type,
        onSuccess: removeMember.type,
        onError: apiRequestedFailed.type,
    })
}
