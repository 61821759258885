import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebase = initializeApp({
    apiKey: 'AIzaSyCBFhX0gdfHyRlb-4GSUx102En1PE36Qo4', // Octopus_GcpFirebaseApiKey
    //authDomain: 'icentrewa-demo-id.firebaseapp.com', // Octopus_GcpFirebaseDomain
    authDomain: 'demoportal.icentrewa.com', // Octopus_GcpFirebaseDomain
    projectId: 'icentrewa-demo-id',
    storageBucket: 'icentrewa-demo-id.appspot.com',
    messagingSenderId: '717603775926',
    appId: '1:30836452604:android:47b80f0ee9a9eff92ebb79',
    measurementId: 'G-EZSJDKHK9R',
})
//const analytics = getAnalytics(firebase);

// const firebase = initializeApp({
//     apiKey: "AIzaSyB1lUWKp45Ofny_jHXtjajqFsOxU7bkfz4",
//     authDomain: "maclocal-project-id.firebaseapp.com",
//     projectId: "maclocal-project-id",
//     storageBucket: "maclocal-project-id.appspot.com",
//     messagingSenderId: "427816391467",
//     appId: "1:427816391467:web:9a72e46b0a015219e26b6e"
// })
export const auth = getAuth(firebase)
