import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'

const AddLocationForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.locations)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/definitions'),
    }

    const validationRules = {
        locationName: [{ type: 'required', message: 'Locations Name is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Add Location{' '}
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <div>
                    <form onSubmit={handleSubmit(props.addLocation)}>
                        <Form
                            mode='form'
                            formData={props.locationData}
                            showColonAfterLabel={false}
                            showValidationSummary={false}>
                            <GroupItem caption='New Location' cssClass='form-group-item'>
                                <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem cssClass='form-group-item' colSpan={6}>
                                    <EmptyItem />
                                    <SimpleItem
                                        dataField='locationName'
                                        validationRules={validationRules.locationName}
                                    />
                                    <SimpleItem dataField='address' />
                                    <SimpleItem dataField='phoneNumber' />
                                    <SimpleItem dataField='comments' editorType='dxTextArea' />
                                </GroupItem>
                            </GroupItem>
                            <EmptyItem />
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                                <ButtonItem
                                    buttonOptions={{
                                        disabled: loading,
                                        icon: loading ? 'refresh' : 'check',
                                        ...submitButtonOptions,
                                    }}
                                    horizontalAlignment='right'
                                />
                                <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddLocationForm
