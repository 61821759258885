import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    banners: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const bannerSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getBanners: (state, action) => {
            state.banners = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingBannerById: (state, action) => {
            const index = state.banners.data.findIndex((banner) => banner.id === action.payload.id)
            if (index >= 0) state.banners[index] = action.payload
            else {
                state.banners.data.push(action.payload)
                state.banners.totalCount++
            }
            state.loading = false
        },
        addNewBanner: (state, action) => {
            state.banners.data.push(action.payload)
            state.banners.totalCount++
            state.loading = false
        },

        updateExistingBanner: (state, action) => {
            const index = state.banners.data.findIndex((banner) => banner.id === action.payload.id)
            if (index >= 0) state.banners[index] = action.payload
            state.loading = false
        },
        removeBanner: (state, action) => {
            const index = state.banners.data.findIndex((banner) => banner.id === action.payload.id)
            if (index >= 0) {
                state.banners.data.splice(index, 1)
                state.banners.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getBanners,
    getExistingBannerById,
    addNewBanner,
    updateExistingBanner,
    removeBanner,
} = bannerSlice.actions
export default bannerSlice.reducer

export const getBannerList = (filter, refresh = false) => {
    let url = `/api/Image/banner/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getBanners.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBannerById = (filter) => {
    let url = `/api/Image/banner/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingBannerById.type,
        onError: apiRequestedFailed.type,
    })
}

//https://axios-http.com/docs/multipart
//https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/FileUploader/FileSelection/MaterialBlueLight/

export const addBanner = (banner, headers) => {
    let url = `/api/Image/banner/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: banner,
        headers: headers,
        onStart: apiRequested,
        onSuccess: addNewBanner.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateBanner = (banner) => {
    let url = `/api/Image/banner/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: banner,
        onStart: apiRequested.type,
        onSuccess: updateExistingBanner.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteBanner = (banner) => {
    let url = '/api/Image/banner/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: banner,
        onStart: apiRequested.type,
        onSuccess: removeBanner.type,
        onError: apiRequestedFailed.type,
    })
}
