import React from 'react'
import Page from '@jumbo/shared/Page'
import Chat from '../pages/messages/chat'

const messagesRoutes = [
    {
        path: '/messages/chat',
        element: <Page component={Chat} />,
    },
]

export default messagesRoutes
